<template>
  <Modal
    hide-mobile-back
    hide-close
    stop-mobile-max-height
    to-top
    @close="onClose"
  >
    <Card :title="''" :description="''" class="card">
      <div class="connect-modal">
        <div class="connect-modal__logo">
          <div :class="{ isEth }" class="leftIcon" />
          <div class="shadow1" />
          <div class="shadow2" />
          <div class="rightIcon" />
          <div class="connect-modal__spin-wrap">
            <div class="connect-modal__spin-elem">
              <span>------------------------------------------</span>
              <span>------------------------------------------</span>
              <span>------------------------------------------</span>
            </div>
          </div>
        </div>
        <div class="connect-modal__title">Wallet Authorization</div>
        <div class="connect-modal__desc">
          You are being redirected to<br />{{
            !isEth ? 'Fiorin Wallet and Bridge' : 'Ethereum Virtual Machine'
          }}
        </div>
      </div>
    </Card>
  </Modal>
</template>

<script>
import Modal from '@/modules/modals/Modal';
import Card from '@/components/ui/Card';
import { computed } from 'vue';
import { useStore } from 'vuex';

const EVENTS = {
  close: 'close',
};

export default {
  components: { Modal, Card },
  emits: [EVENTS.close],
  setup(_, { emit }) {
    const store = useStore();
    const isEth = computed(
      () => store.getters['localUiSettings/showConnectModal'] === 'eth'
    );

    const onClose = () => {
      emit(EVENTS.close);
    };

    return {
      onClose,
      isEth,
    };
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/styles/colors';
@import '@/assets/styles/base';

.connect-modal {
  height: auto;
  height: 380px;
  overflow: hidden;
  max-height: 380px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  // margin-left: -20px;
  overflow-y: auto;
  margin-bottom: 65px;
  box-sizing: border-box;
  font-family: Gotham_Pro_Regular;
  line-height: 20px;

  @media screen and (max-width: 1024px) {
    height: 100%;
    max-height: 100%;
    padding-bottom: 29px;
  }

  @keyframes run {
    from {
      transform: translateX(-500px);
    }
    to {
      transform: translateX(1683.35px);
    }
  }

  &__title {
    margin-top: -18px;
    color: #fff;
    opacity: 0.7;
    font-size: 24px;
    font-family: Gotham_Pro_Medium;
  }

  &__desc {
    text-align: center;
    margin-top: 26px;
    color: #fff;
    opacity: 0.7;
    font-size: 15px;
    line-height: 20px;
    font-family: Gotham_Pro_Regular;
  }

  &__logo {
    position: relative;
    width: 183px;
    height: 140px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .shadow1 {
      left: 50px;
      width: 13px;
      height: 2px;
      background: rgba(18, 23, 34, 0.8);
      position: absolute;
      z-index: 1;
    }

    .shadow2 {
      right: 50px;
      width: 13px;
      height: 2px;
      background: rgba(18, 23, 34, 0.8);
      position: absolute;
      z-index: 1;
    }

    .leftIcon {
      border-radius: 12px;
      width: 50px;
      height: 50px;
      background-image: url('~@/assets/fiorin.svg');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: 50%;
      box-shadow: 15px 0 10px 0 rgba(0, 0, 0, 0.1);

      &.isEth {
        background-image: url('~@/assets/eth.svg');
      }
    }

    .rightIcon {
      border-radius: 12px;
      width: 50px;
      height: 50px;
      background-image: url('~@/assets/dxs.svg');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: 50%;
      box-shadow: -15px 0 10px 0 rgba(0, 0, 0, 0.1);
    }
  }

  &__spin-wrap {
    margin: auto;
    width: 74px;
    overflow: hidden;
    position: absolute;
    top: 60px;
    letter-spacing: 2px;
    left: calc(50% - 37px);
  }

  &__spin-elem {
    white-space: nowrap;
    animation: run 100s linear infinite;
    color: #fff;
    opacity: 0.5;
  }
}

body.light {
  .connect-modal {
    &__desc {
      color: #444;
    }

    &__title {
      color: #444;
    }

    .shadow1 {
      background: rgba(236, 236, 236, 0.4);
    }

    .shadow2 {
      background: rgba(236, 236, 236, 0.4);
    }
  }
}
</style>
