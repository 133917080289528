<template>
  <Modal hide-mobile-back hide-close stop-mobile-max-height to-top></Modal>
</template>

<script>
import Modal from '@/modules/modals/Modal';

export default {
  name: 'Overlay',
  components: {
    Modal,
  },
  setup() {},
};
</script>
<style lang="scss" scoped></style>
