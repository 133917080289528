<template>
  <Modal @close="$emit(EVENTS.close)">
    <Card
      :title="$t('popups.liquidityFundraiser.titles.received').toUpperCase()"
      class="card"
      :body-class="'card-full'"
    >
      <div class="payments-received">
        <i18n-t
          keypath="popups.liquidityFundraiser.paymentsReceived1"
          tag="div"
        >
          <template #percent>
            <span class="nowrap">{{ userShare }}</span>
          </template>
          <template #total>
            <span class="nowrap" v-html="totalLiquidity" />
          </template>
          <!-- <template #initial>
            <span class="nowrap">{{ userLiquidity }}</span>
          </template>
          <template #netInterest>
            <span class="nowrap">{{ userReturn }}</span>
          </template> -->
        </i18n-t>
        <div class="payments-received--export">
          {{ $t('popups.liquidityFundraiser.paymentsReceived2.1') }}
          <span class="underline" @click="exportHistory">{{
            $t('popups.liquidityFundraiser.paymentsReceived2.2')
          }}</span>
          {{ $t('popups.liquidityFundraiser.paymentsReceived2.3') }}
        </div>
      </div>
    </Card>
  </Modal>
</template>
<script>
import Modal from '@/modules/modals/Modal';
import Card from '@/components/ui/Card';
import notify from '@/plugins/notify';
import useUserActions from '@/compositions/useUserActions';
import { connApp, getErrorDetails } from '@/helpers/signalR';
import { computed, ref, onMounted } from 'vue';
import { toCurrencyFormat, usdBsvFormatAmount } from '@/helpers/utils';
import authMixin from '@/mixins/auth.mixin';
import useAuth from '@/compositions/useAuth';

const EVENTS = {
  close: 'close',
};

export default {
  mixins: [authMixin],
  components: { Modal, Card },
  props: {
    liquidityFund: {
      required: true,
    },
    receiveValue: {
      type: [String, Number],
      default: 0,
    },
    symbolLabel: {
      type: String,
      default: '',
    },
    userProvisionsKeys: {
      type: Array,
      default: () => [],
    },
  },
  emits: [EVENTS.close],
  setup(props, { emit }) {
    const auth = useAuth();
    const liquidityProvided = ref({});
    const userActions = useUserActions(props, { emit });
    const float = computed(() => (auth.isFiorinMode.value ? 2 : 5));

    const totalLiquidity = computed(() => {
      let value = usdBsvFormatAmount(
        liquidityProvided.value.userLiquidity +
          props.liquidityFund.userPaymentReceived +
          props.liquidityFund.userPaymentLeft -
          props.liquidityFund.userFunded,
        '',
        true,
        true
      );

      if (props.userProvisionsKeys.length > 1) {
        return `${value} ${props.symbolLabel}`;
      }
      const tokenKey = props.userProvisionsKeys[0];

      value = usdBsvFormatAmount(
        liquidityProvided.value.userLiquidity +
          (props.liquidityFund.userPaymentReceivedByCurrency[tokenKey] || 0) +
          props.liquidityFund.userPaymentLeft -
          (props.liquidityFund.userFundedByCurrency[tokenKey] || 0),
        '',
        true,
        true
      );

      return `${value} ${props.symbolLabel}`;
    });

    const userLiquidity = computed(() => {
      return (
        toCurrencyFormat(
          liquidityProvided.value.userLiquidity,
          null,
          float.value
        ) + auth.currency.value
      );
    });

    const userShare = computed(() => {
      return toCurrencyFormat(liquidityProvided.value.userSharePct, null, 5);
    });

    const userReturn = computed(() => {
      return (
        toCurrencyFormat(
          props.liquidityFund.userPaymentReceived +
            props.liquidityFund.userPaymentLeft -
            props.liquidityFund.userFunded,
          null,
          float.value
        ) + auth.currency.value
      );
    });

    const getDetails = async () => {
      let error;
      let result = await connApp
        .invokeWithRetry('GetLiquidityProvided')
        .catch((err) => {
          error = getErrorDetails(err);
        });
      if (!error) {
        console.log('GetLiquidityProvided', result);
        liquidityProvided.value = result;
      } else {
        notify({
          text: error.message,
          type: 'info',
        });
      }
    };

    const exportHistory = () => {
      userActions.exportHistory();
    };

    onMounted(() => {
      getDetails();
    });

    return {
      exportHistory,
      EVENTS,
      userShare,
      totalLiquidity,
      userLiquidity,
      userReturn,
    };
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/styles/colors';
@import '@/assets/styles/base';

.payments-received {
  min-height: 193px;
  font-family: Gotham_Pro_Regular;
  line-height: 18px;
  padding: 20px 20px 0;
  margin-bottom: 15px;

  @media screen and (min-width: 1024px) {
    padding-right: 40px;
    padding-left: 40px;
  }

  &--export {
    margin-top: 25px;
  }

  span.underline {
    text-decoration-line: underline;
    cursor: pointer;
  }

  .nowrap {
    white-space: nowrap;
  }
}
</style>
