/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'arrowrightbold': {
    width: 9,
    height: 15,
    viewBox: '0 0 9 15',
    data: '<path pid="0" d="M1.526 14.059l-1.51-1.51 5.05-5.05-5.05-5.048L1.526.94 8.085 7.5l-6.559 6.559z" _fill="#0796EA"/>'
  }
})
