<template>
  <div>
    <div
      :class="{
        notLoginStandalone: wd.navigator.standalone || isTelegram,
        isFiorinNullableBalance,
      }"
      class="bottom-login"
      @click="login"
    >
      <template v-if="!isLoggedIn">
        <div class="bottom-login__title">CONNECT WALLET</div>
        <div class="bottom-login__desc">get up to $100 of trading capital</div>
      </template>
      <template v-if="isFiorinNullableBalance">
        <div class="bottom-login__title">ADD FUNDS</div>
      </template>
    </div>
    <div
      v-if="wd.navigator.standalone || isTelegram"
      class="bottom-login-area"
    />
  </div>
</template>
<script>
import { useModals } from '@/modules/modals/api';
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useFiorinBalanceNull } from '@/compositions/useFiorinBalanceNull';
import { isRunningStandaloneAndroid, isAndroid } from '@/helpers/detects';

export default {
  setup() {
    const { showModal, modalsByName } = useModals();
    const store = useStore();
    const { isFiorinNullableBalance } = useFiorinBalanceNull();

    const isLoggedIn = computed(() => store.getters['auth/isLoggedIn']);

    const login = () => {
      if (isFiorinNullableBalance.value) {
        return store.dispatch('fiorin/viewDeposit');
      }
      showModal(modalsByName.login);
    };

    const isTelegram = computed(() =>
      Boolean(store.getters['telegram/instance'])
    );

    return {
      isLoggedIn,
      wd: window,
      login,
      isTelegram,
      isFiorinNullableBalance,
      isRunningStandaloneAndroid,
      isAndroid,
    };
  },
};
</script>
<style lang="scss" scoped>
.bottom-login-area {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 34px;
  background: #fff;
  z-index: 1;
}

.bottom-login {
  min-height: 60px;
  box-sizing: border-box;
  position: fixed;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #fff;
  width: 100%;

  &.isFiorinNullableBalance {
    bottom: 0;
  }

  @media (min-width: 480px) {
    justify-content: center;
  }

  &.notLoginStandalone {
    // min-height: 83px;
    bottom: 34px;
  }

  &__title {
    font-size: 16px;
    line-height: 20px;
    font-family: 'Gotham_Pro_Medium';
    color: #000;
  }

  &__desc {
    user-select: none;
    font-size: 11px;
    font-family: 'Gotham_Pro';
    text-align: center;
    color: #000;
    opacity: 0.5;
    line-height: 14px;
    text-transform: uppercase;
    margin-top: 5px;
  }
}
</style>
