export const getWidgetOptions = (optsToOverwrite = {}) => ({
  client_id: 'tdxp',
  interval: '30',
  toolbar_bg: '#00000000',
  loading_screen: {
    backgroundColor: '#00000000',
    foregroundColor: '#00000000',
  },
  custom_css_url: '/cl_css/cl_css.css',
  locale: 'en',
  numeric_formatting: { decimal_sign: ',' },
  auto_save_delay: 1,
  disabled_features: [
    'header_screenshot',
    'volume_force_overlay',
    'header_compare',
    'header_symbol_search',
    'header_settings',
    'timeframes_toolbar',
    'display_market_status',
    'create_volume_indicator_by_default',
  ],
  enabled_features: [
    'use_localstorage_for_settings',
    'save_chart_properties_to_local_storage',
  ],
  autosize: true,
  overrides: stylesToOverride,
  ...optsToOverwrite,
});

export const stylesToOverride = {
  'paneProperties.backgroundType': 'solid',

  'paneProperties.backgroundGradientStartColor': '#121722',
  'paneProperties.backgroundGradientEndColor': '#121722',

  'scalesProperties.lineColor': 'rgba(0, 0, 0, 0)', // main border
  'scalesProperties.textColor': '#AAA',

  'paneProperties.crossHairProperties.color': '#ccc',
  'paneProperties.crossHairProperties.width': 1,
  'paneProperties.crossHairProperties.style': 0,
  'mainSeriesProperties.showPriceLine': true,
  'mainSeriesProperties.priceLineColor': '#ccc',

  'paneProperties.legendProperties.showSeriesTitle': false, //hide market before OHLC
  // grids on graph
  'paneProperties.vertGridProperties.color': 'transparent',
  'paneProperties.vertGridProperties.style': 1,
  'paneProperties.horzGridProperties.color': '#transparent',
  'paneProperties.horzGridProperties.style': 1,

  // candles color
  'mainSeriesProperties.candleStyle.downColor': 'rgb(222, 94, 86)',
  'mainSeriesProperties.candleStyle.upColor': 'rgb(80, 159, 150)',
  'mainSeriesProperties.candleStyle.borderDownColor': 'rgb(222, 94, 86)',
  'mainSeriesProperties.candleStyle.borderUpColor': 'rgb(80, 159, 150)',
  'mainSeriesProperties.candleStyle.wickDownColor': 'rgb(222, 94, 86)',
  'mainSeriesProperties.candleStyle.wickUpColor': 'rgb(80, 159, 150)',
  // Hollow Candles styles
  'mainSeriesProperties.hollowCandleStyle.downColor': 'rgb(222, 94, 86)',
  'mainSeriesProperties.hollowCandleStyle.upColor': 'rgb(80, 159, 150)',
  'mainSeriesProperties.hollowCandleStyle.borderDownColor': 'rgb(222, 94, 86)',
  'mainSeriesProperties.hollowCandleStyle.borderUpColor': 'rgb(80, 159, 150)',
  'mainSeriesProperties.hollowCandleStyle.wickDownColor': 'rgb(222, 94, 86)',
  'mainSeriesProperties.hollowCandleStyle.wickUpColor': 'rgb(80, 159, 150)',

  // Heikin Ashi styles
  'mainSeriesProperties.haStyle.upColor': 'rgb(80, 159, 150)',
  'mainSeriesProperties.haStyle.downColor': 'rgb(222, 94, 86)',
  'mainSeriesProperties.haStyle.borderUpColor': 'rgb(80, 159, 150)',
  'mainSeriesProperties.haStyle.borderDownColor': 'rgb(222, 94, 86)',

  // Bar styles
  'mainSeriesProperties.barStyle.downColor': 'rgb(222, 94, 86)',
  'mainSeriesProperties.barStyle.upColor': 'rgb(80, 159, 150)',
  'symbolWatermarkProperties.transparency': 10,
};
