import { HubConnectionState } from '@microsoft/signalr';
import { sleep, waitUntil } from '@/helpers/retry';
import { emitEvents } from '@/helpers/signalR/emitEvents';
import { OneWeekMs } from '@/helpers/timeConstants';

const connectionIsNotEstablishedErrorText = 'Connection is not established';

const invokeConfigurable = async (
  host,
  getConnection,
  emit,
  methodName,
  immediateOnly,
  allowLogging = true,
  args
) => {
  // [Vadim] Please do not change the emit events, they are used.

  if (!host) {
    throw new Error('Missing host');
  }

  if (!getConnection) {
    throw new Error('Missing getConnection');
  }

  if (typeof getConnection !== 'function') {
    throw new Error('getConnection should be a function');
  }

  if (!emit) {
    throw new Error('Missing emit');
  }

  if (typeof emit !== 'function') {
    throw new Error('emit should be a function');
  }

  if (!methodName) {
    throw new Error('Missing methodName');
  }

  if (typeof immediateOnly !== 'boolean') {
    throw new Error('immediateOnly should be a boolean');
  }

  const enterState = getConnection().state;
  let lastInvokeState = enterState;

  const dateStart = new Date();

  const getElapsedSeconds = () => {
    // in seconds
    return (new Date() - dateStart) / 1000;
  };

  const invokeTraceId = Math.random().toString(36).substring(7);
  let tryNumber = 0;
  let hasAwaitedForReconnect = false;

  const getLogs = () => {
    const logs = [];
    logs.push('\n\n');
    logs.push('\n');
    logs.push('Method:');
    logs.push(methodName);
    logs.push('\n\n');
    logs.push('Args:');
    logs.push(args ? JSON.stringify(args) : 'null!');
    logs.push('\n');
    logs.push('Host:');
    logs.push(host);
    logs.push('\n');
    logs.push('Current State:');
    logs.push(getConnection().state);
    logs.push('\n');
    logs.push('Last Invoke State:');
    logs.push(lastInvokeState);
    logs.push('\n');
    logs.push('Enter state:');
    logs.push(enterState);
    logs.push('\n');
    logs.push('ImmediateOnly:');
    logs.push(immediateOnly);
    logs.push('\n');
    logs.push('tryNumber:');
    logs.push(tryNumber);
    logs.push('\n');
    logs.push('invokeTraceId:');
    logs.push(invokeTraceId);
    logs.push('\n');
    logs.push('Total Invoke ElapsedSeconds:');
    logs.push(getElapsedSeconds());
    logs.push('\n');
    logs.push('#SignalRConnection #invoke');

    return logs;
  };

  const logDebug = (...args) => {
    if (allowLogging) {
      console.debug(...[...args, ...getLogs()]);
    }
  };

  const logError = (...args) => {
    if (allowLogging) {
      console.error(...[...args, ...getLogs()]);
    }
  };

  const logFatal = (...args) => {
    console.error(...[...args, ...getLogs()]);
  };

  // const logWarn = () => {
  //   if (allowLogging) {
  //     console.warn(...[...args, ...getLogs()]);
  //   }
  // };

  const checkStateBeforeInvoke = async () => {
    if (getConnection().state !== HubConnectionState.Connected) {
      if (immediateOnly) {
        emit(emitEvents.connectionNotEstablished);

        logError(
          'Connection is not established, but immediateOnly is true, so will not wait for reconnect'
        );

        throw new Error(connectionIsNotEstablishedErrorText);
      }

      logDebug(
        'Connection is not established before trying to invoke method..',
        '\n',
        'waiting for recovery...'
      );

      hasAwaitedForReconnect = true;

      let connected = await waitUntil(
        'waitBeforeInvoke #SignalRConnection #invoke #waitUntil',
        // need to check it for a short time to send notification to the user, if it's not recovered
        10_000,
        100,
        () => getConnection().state === HubConnectionState.Connected
      );

      if (connected) {
        logDebug(
          'Connection established after waiting within 10sec #1',
          '\n',
          'going to invoke...'
        );
        emit(emitEvents.connectionEstablished);

        return;
      }

      // we need to emit, and then continue waiting for recovery
      emit(emitEvents.connectionNotEstablished);

      //no need to this log anymore
      // logError(
      //   'Connection is not established even after waiting for recovery... #1',
      //   '\n',
      //   'Now going to wait for 24hr',
      //   '\n',
      // );

      connected = await waitUntil(
        'waitBeforeInvoke #SignalRConnection #invoke #waitUntil',
        OneWeekMs,
        200,
        () => getConnection().state === HubConnectionState.Connected
      );

      if (connected) {
        logDebug(
          'Connection established after waiting  #2',
          '\n',
          'going to invoke...'
        );

        emit(emitEvents.connectionEstablished);

        return;
      }

      logError(
        'Connection is not established even after waiting for recovery #2.',
        '\n',
        'It was the last try, will not wait anymore, will throw an error',
        '\n'
      );

      emit(emitEvents.connectionNotEstablished);

      throw new Error(connectionIsNotEstablishedErrorText);
    }
  };

  // keep 'resolvedWithOk' outside of the tryInvoke.
  let resolvedWithOk = false;

  let stopRetry = false;

  const tryInvoke = async () => {
    if (tryNumber > 0) {
      const sleepMs = Math.min(tryNumber * 300, 10_000);
      // logDebug('going to sleep for ', sleepMs, 'ms');

      if (sleepMs > 0) {
        await sleep(sleepMs);
        // console.debug(`Woke up after sleeping ${sleepMs}ms \n`);
      }
    }

    await checkStateBeforeInvoke();

    let resolvedWithError = false;
    let delayMessageWasShown = false;

    const dateStart = new Date();

    const intervalId = setInterval(() => {
      const elapsedSeconds = (new Date() - dateStart) / 1000;
      if (resolvedWithOk || resolvedWithError) {
        clearInterval(intervalId);
        if (delayMessageWasShown) {
          // use exactly resolvedWithOk, do not change to 'if resolvedWithError'
          if (!resolvedWithOk) {
            emit(emitEvents.invocationRejected);
            // [Vadim] not needed, it's too much. We have a console.error in the catch block
            // logError('Invocation promise rejected');
          } else {
            emit(emitEvents.invocationResolved);
            logDebug('Invocation promise resolved');
          }
        }
      } else {
        if (!delayMessageWasShown) {
          emit(emitEvents.invocationIsTooLong);
        }

        delayMessageWasShown = true;

        logError(
          'Not error, but invocation promise still not resolved, nor rejected, 15 sec',
          '\n',
          'elapsedSeconds:',
          elapsedSeconds
        );
      }
    }, 15_000);

    try {
      lastInvokeState = getConnection().state;
      const resultPromise = getConnection().invoke(methodName, ...args);
      const result = await resultPromise;
      resolvedWithOk = true;
      stopRetry = true;
      return result;
    } catch (e) {
      resolvedWithError = true;
      const stateWhenError = getConnection().state;

      const isNetworkErrorMessage = e?.message?.includes(
        `not in the 'Connected' State.`
      );

      const isNetworkError =
        isNetworkErrorMessage ||
        stateWhenError !== HubConnectionState.Connected;

      if (isNetworkError) {
        emit(emitEvents.connectionNotEstablished);
      }

      if (!isNetworkError || immediateOnly) {
        stopRetry = true;

        if (!isNetworkError || allowLogging) {
          logFatal(
            'Error when invoke!',
            '\n',
            'error message:',
            e.message,
            '\n',
            'error:',
            e,
            '\n'
          );
        }

        emit(emitEvents.invocationError, e);
        throw e;
      }

      tryNumber++;
      logError('Network error when invoke, will try to invoke again', e);

      hasAwaitedForReconnect = true;
    }
  };
  let result;

  if (!immediateOnly) {
    do {
      result = await tryInvoke();
    } while (!stopRetry);
  } else {
    result = await tryInvoke();
  }

  if (hasAwaitedForReconnect) {
    logDebug(
      '--------------------------------------------',
      '\n',
      'Awaiting connection has saved a life!',
      '\n',
      '--------------------------------------------',
      '\n'
    );
  }
  return result;
};

export { invokeConfigurable };
