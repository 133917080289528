import store from '@/store';
import { WalletNames } from '@/wallets/common';
import router from '@/router';
import { connApp } from '@/helpers/signalR';
import { hideSplash, showSplash } from '@/helpers/splash';
import { emitEvents } from '@/helpers/signalR/emitEvents';

function setPendingProviderName(provider) {
  if (!provider) {
    console.debugError('No provider passed to setPendingProviderName');
    clearPendingProviderName();
    return;
  }
  sessionStorage.setItem('pendingProviderName', provider);
}

function clearPendingProviderName() {
  sessionStorage.removeItem('pendingProviderName');
}

function getPendingProviderName() {
  return sessionStorage.getItem('pendingProviderName');
}

const getInitialState = () => ({
  isLoggingOut: false,
  isLoggedIn: false,
  dxsAuthData: null,
  authFinished: false,
});

const getters = {
  isLoggedIn: (state) => state.isLoggedIn,
  isAuthFinished: (state) => state.authFinished,

  provider: (state, getters) =>
    getters['isLoggedIn'] ? getters['account']?.provider : null,

  isFiorin: (state, getters) => getters['provider'] === WalletNames.fiorin,

  isFiorinMode: (state, getters) =>
    !getters['isLoggedIn'] || getters['isFiorin'],

  isFiorinAsProxy: (state, getters, rootState, rootGetters) =>
    getters['isFiorin'] && rootGetters['fiorin/isFiorinAsProxy'],

  isHandCash: (state, getters) => getters['provider'] === WalletNames.handCash,

  isDotWallet: (state, getters) =>
    getters['provider'] === WalletNames.dotWallet,

  // do not check isLoggedIn here
  dxsAuthData: (state) => state.dxsAuthData,

  account: (state, getters, rootState, rootGetters) => {
    if (!getters['isLoggedIn']) {
      return null;
    }

    let activeAccount = rootGetters['accounts/activeAccount'];
    return activeAccount;
  },

  userId: (state, getters) =>
    getters['isLoggedIn'] ? state.dxsAuthData?.userId : null,
};
const mutations = {
  setLoggedIn(state, value) {
    state.isLoggedIn = value;
  },

  setDxsAuthData(state, value) {
    state.dxsAuthData = value;
  },

  setLoggingOut(state, value) {
    state.isLoggingOut = value;
  },
  setAuthFinished(state, value) {
    state.authFinished = value;
  },
};

let actions = {
  async initAuth({ state, getters, commit, dispatch, rootGetters }) {
    if (state.isLoggingOut) {
      await dispatch('clear');
      commit('setLoggingOut', false);
      return;
    }

    const theme = rootGetters['settings/theme'];
    console.debug('#initAuth #auth, state:', state, 'theme:', theme);
    window.showState();

    const connected = await connectPendingProvider(
      getPendingProviderName(),
      theme
    );

    clearPendingProviderName();
    if (connected) {
      return;
    }

    await dispatch('accounts/initActiveAccountId', null, { root: true });
    // account should be taken before the cleanup
    const account = getters['account'];
    if (!state.isLoggedIn) {
      await dispatch('clear');
      return;
    }

    if (!account) {
      console.debugError('No active account found #initAuth #auth');

      await dispatch('clear');

      return;
    }

    if (
      account.provider === WalletNames.fiorin ||
      account.provider === WalletNames.handCash
    ) {
      try {
        showSplash(theme);
        if (account.provider === WalletNames.fiorin) {
          await dispatch('fiorin/initAuth', account, { root: true });
        }
        if (account.provider === WalletNames.handCash) {
          await dispatch('handcash/initAuth', account, { root: true });
        }
      } catch (e) {
        console.debugError(
          'Error when waitForAuthStatus for #initAuth #auth',
          e
        );

        console.log('calling clear after auth fail #initAuth #auth', e);
        await dispatch('clear');

        console.log(
          'calling hide splash after auth fail and clear #initAuth #auth',
          e
        );
        hideSplash();

        return;
      }

      return;
    }

    if (
      account.provider === WalletNames.dotWallet
      //&& !localStorage.getItem('showDWUsers')
    ) {
      // showDWUsers = true;
      // todo: test
      // if (dwAuthCode) {
      //   const dotwalletWallet = new DotwalletConnector({ code: dwAuthCode });
      //   store.dispatch('connectors/setActiveConnect', dotwalletWallet);
      // }
      // const dotwalletWallet = new DotwalletConnector(activeConnect);
      // await store.dispatch('connectors/setActiveConnect', dotwalletWallet);
      // dotwalletWallet.connect()
      // if (activeAccount.authorizeCode) {
      //   await activeConnect.authorizeCode(activeConnect.code);
      // }

      return;
    }

    await dispatch('clear');

    console.debugError(
      'Unsupported provider #initAuth',
      account.provider,
      'account:',
      account
    );
  },

  async startLogin({ dispatch }, { provider, data }) {
    console.debug('#auth  startAuth', provider, data);

    if (!provider) {
      throw new Error('No provider passed to startAuth');
    }

    setPendingProviderName(provider);

    if (provider === WalletNames.fiorin) {
      await dispatch(
        'fiorin/startLogin',
        {
          options: data,
        },
        {
          root: true,
        }
      );
    } else if (provider === WalletNames.handCash) {
      await dispatch('handcash/startLogin', data, {
        root: true,
      });
    } else {
      console.debugError('Unsupported provider #startAuth', provider);
    }
  },

  async switchAccount({ dispatch, rootGetters }, login) {
    console.debug('#switchAccount #auth login:', login);

    const accounts = rootGetters['accounts/accounts'];

    const foundAccount = accounts.find((acc) => acc.userName.startsWith(login));
    if (!foundAccount) {
      console.debugError(
        `Account not found #switchAccount #auth, value: ${login}, accounts: ${JSON.stringify(
          accounts
        )}`
      );
      return null;
    } else {
      console.debug(
        'Account found #switchAccount #auth, value: ',
        foundAccount
      );
    }

    await dispatch('accounts/setNextAccountId', foundAccount.id, {
      root: true,
    });

    window.location.reload();
  },

  async onAuthenticated({ commit, dispatch }, account) {
    console.debug('#onAuthenticated  #auth #actions');

    if (!account) {
      const error = 'No account provided #auth #actions #authenticated';
      console.debugError(error);
      throw new Error(error);
    }

    commit('setAuthFinished', false);

    await dispatch('accounts/addAccount', account, { root: true });

    // it passes token and provider to socket and restart it
    await updateSocket(account);

    // set dxsAuthData

    const settings = await connApp.invokeWithRetry('GetUser');

    const authInfo = {
      provider: settings?.auth?.provider,
      providerId: settings?.auth?.providerId,
      userName: settings?.auth?.userName,
      userId: settings?.user?.id,
    };

    if (authInfo.userId) {
      commit('setDxsAuthData', authInfo);
    } else {
      console.debugError(
        'No userId returned from GetUser #connectors #actions #authenticated'
      );
    }

    // this block should go after the dxsAuthData is set
    // the custom module handlers should be used after this block, since they use atLeast isFiorinMode
    {
      await dispatch('accounts/setActiveAccountId', account.id, { root: true });
      clearPendingProviderName();
      commit('setLoggedIn', true);
    }

    const loadSubsystems = async () => {
      await Promise.all([
        dispatch('settings/onAuthenticated', settings, {
          root: true,
        }),
        //dxsAuthData is used here:
        dispatch('user/onAuthenticated', null, { root: true }),
        dispatch('session/onAuthenticated', null, { root: true }),
        dispatch('positions/onAuthenticated', null, { root: true }),
        dispatch('bounty/onAuthenticated', null, { root: true }),
      ]);
    };

    try {
      await loadSubsystems();
    } catch (e) {
      console.debugError('Error when onAuthenticated', e);
    }

    connApp.subscribeToClientEvent(emitEvents.reconnected, async () => {
      console.debug('#reconnected loadSubsystems');
      try {
        await loadSubsystems();
      } catch (e) {
        console.error('Error when loadSubsystems onReconnect', e);
      }
    });

    hideSplash();
    closeLoginModal();

    commit('setAuthFinished', true);

    // only for the js scripts to work when app is not loaded,
    // not to deserialized whole vuex
    localStorage.setItem('dxsLoggedIn', 'true');
    localStorage.setItem('dxsEverLoggedIn', 'true');
  },

  async logout({ dispatch, getters, commit }) {
    commit('setLoggingOut', true);
    let executed = false;

    await dispatch('wallet/logout', null, { root: true })
      .then(() => (executed = true))
      .catch((e) => {
        console.error(
          '#auth #logout error when wallet/logout',
          'error:',
          JSON.stringify(e)
        );
        executed = true;
      });

    if (getters['isLoggedIn']) {
      console.error(
        'Not logged out after all events gone #logout #auth',
        'executed:',
        executed
      );
    }

    // If we want to logout even if fiorin is not responding, uncomment this code:
    // await waitUntil(
    //   '#logout wait for isLoggedOut',
    //   30_000,
    //   100,
    //   () => executed
    // );
    // await dispatch('onLogout');

    // [Vadim] I commented this line because it's not clear why it's needed
    // dispatch('session/updateInfo', 'USD', { root: true });
  },

  async onLogout({ dispatch, commit }) {
    console.debug('#onLogout #auth #store');

    await dispatch('clear');
    commit('setLoggingOut', false);

    await customRedirect();
    window.location.reload();
  },

  async clear({ commit, dispatch }) {
    console.debug('#clear #auth #actions');
    commit('setLoggedIn', false);
    localStorage.removeItem('dxsLoggedIn');
    commit('setDxsAuthData', null);
    console.debug('#clear sybsystems #auth #actions #wallet');
    try {
      await Promise.all([
        dispatch('wallet/clear', null, { root: true }),
        dispatch('accounts/clear', null, { root: true }),
        dispatch('settings/clear', null, { root: true }),
      ]);
    } catch (e) {
      console.error('Error when clearing subsystems #auth #actions #wallet', e);
    }
    console.debug('#clear sybsystems - END #auth #actions #wallet');
  },
};

const customRedirect = async () => {
  console.debug('#customRedirect #auth');
  const href = router.currentRoute.value?.href || '/';

  if (['index?code', 'authToken'].some((part) => href.includes(part))) {
    await router.push('/');
  } else {
    await router.push(href);
  }
};

function closeLoginModal() {
  // [Vadim] not sure why, it's works without it (at least for fiorin)
  window.document.dispatchEvent(
    new CustomEvent('closeLogin', { bubbles: true })
  );
}

async function updateSocket(account) {
  console.debug('Changing auth #auth #actions #changeAuth');
  // 1) if user loaded page but wasn't logged in, then connection will be established with unauthorized user
  // 2) if user is logged in, when refresh page, then connection will be unconnected
  await connApp.applyAccessData(account.provider, account.accessToken);
}

async function connectPendingProvider(pendingProviderName, theme) {
  if (!pendingProviderName) {
    return false;
  }
  if (pendingProviderName === WalletNames.fiorin) {
    // nothing to do, just ignore
    return false;
  }
  if (pendingProviderName === WalletNames.handCash) {
    console.debug('#auth  connectPendingProvider #handCash');

    const canConnect = await store.dispatch('handcash/canConnect');
    if (!canConnect) {
      clearPendingProviderName();
      return false;
    }

    try {
      showSplash(theme);
      return await store.dispatch('handcash/completeConnect');
    } catch (e) {
      console.debugError('Error when connect HandCash', e);
      // even in case of error, the already connected account will be used, if any
      return false;
    }
  } else {
    console.debug('Unsupported pendingProviderName #auth', pendingProviderName);
    clearPendingProviderName();
  }

  return false;
}

export default {
  namespaced: true,
  state: getInitialState(),
  mutations,
  actions,
  getters,
  cachedPaths: ['auth.isLoggedIn', 'auth.isLoggingOut', 'auth.dxsAuthData'],
};
