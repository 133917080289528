export const emitEvents = {
  connectionEstablished: 'connectionEstablished',
  cannotEstablishConnection: 'cannotEstablishConnection',
  connectionNotEstablished: 'connectionNotEstablished',
  reconnecting: 'reconnecting',
  reconnected: 'reconnected',
  restarting: 'restarting',
  unauthorized: 'unauthorized',
  invoke: 'invoke',
  invocationError: 'invocationError',
  invocationIsTooLong: 'invocationIsTooLong',
  invocationResolved: 'invocationResolved',
  invocationRejected: 'invocationRejected',
};
