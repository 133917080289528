<template>
  <div
    class="form-position-confirm"
    :class="{
      hasLoyalty: +loyaltyFeeRate && loyaltyReset,
      hasEstPl: !formData.entryPrice,
      maxForm: +loyaltyFeeRate && loyaltyReset && !formData.entryPrice,
      bountyInProgram:
        isBounty &&
        accountBalance.bounty >= formData.marginValue &&
        referralProgram,
    }"
  >
    <div class="form-position-confirm__main-info">
      <PanelInfoLabelValue
        :title="`Margin, ${marginAmountFormatted}`"
        :value="marginAmount"
        :large-value="true"
        :clicked="$isFiorin && !isBounty"
        class="form-position-confirm__stats-field"
        @click="onChooseCollateral"
      />
      <PanelInfoLabelValue
        :title="`${$t('order.card.titles.amount')}, USD`"
        :value="formData.amountBtc"
        class="form-position-confirm__stats-field"
      />
      <PanelInfoLabelValue
        :title="$t('order.card.titles.entryPrice')"
        :value="`${prefixEntry} ${toCurrencyFormat(
          entryPrice,
          null,
          floatList[activeMarket.name]
        )}`"
        class="form-position-confirm__stats-field"
      />
      <PanelInfoLabelValue
        v-if="!formData.entryPrice"
        :title="$t('order.card.titles.estPl')"
        :is-red="plAmount < 0"
        :is-green="plAmount > 0"
        :value="plValue"
        class="form-position-confirm__stats-field"
      />
    </div>

    <div
      v-if="
        +loyaltyFeeRate &&
        loyaltyReset &&
        !settings.doNotShowLiquidityFeeWarning
      "
      v-html="$t('order.card.titles.liqFeeHint')"
      class="loyalty-hint"
    />
    <ItemLabelValue
      :label="$t('order.card.titles.leverage')"
      :hint-options="{ top: '-55px', left: '80px' }"
      :hint-title="'Example comment: it allows<br>you to multiply your buying<br>power. Example: to open a Ƀ<br>100 position at 100x leverage,<br>you need just Ƀ 1 in actual<br>balance.'"
      :value="formData.leverage + 'x'"
      width-hint="190px"
      class="form-position-confirm__stats-field mt24"
    />
    <ItemLabelValue
      :label="$t('order.card.titles.liquidationPrice')"
      :value="`${prefixLiquidation} ${toCurrencyFormat(
        liquidationPrice,
        null,
        floatList[activeMarket.name]
      )}`"
      class="form-position-confirm__stats-field mt24"
    />
    <ItemLabelValue
      v-if="+loyaltyFeeRate && loyaltyReset"
      :label="$t('blocks.titles.loyaltyFee')"
      :hint-options="{ top: '-55px', left: '100px' }"
      :hint-title="$t('blocks.titles.loyaltyFeeHint')"
      :value="`${loyaltyFeeRate.toFixed(2)}%`"
      class="form-position-confirm__stats-field mt24"
    />
    <div
      v-if="
        isBounty &&
        accountBalance.bounty >= formData.marginValue &&
        referralProgram
      "
      class="loyalty-hint"
    >
      IMPORTANT: Trading positions opened with USD-Bounty will not earn activity
      points toward completing your ongoing challenge
    </div>
    <div
      v-if="config && config.margin_pool_account"
      :class="{ standalone: wd.navigator.standalone || isTelegram }"
      class="payment-container"
    >
      <FormPositionConfirmPayment
        :form-data="formData"
        :type="orderType"
        :is-bounty="isBounty"
        :to="config.margin_pool_account"
        :margin-value-custom="marginValueCustom"
        :not-stable-value="isNotStable ? `${marginAmount}` : ''"
        @payment="emit('payment')"
        @error="emit('error')"
        @close="emit('onCloseOrderConfirm')"
      />
    </div>
  </div>
</template>
<script>
import FormPositionConfirmPayment from './FormPositionConfirmPayment.vue';
import {
  toCurrencyFormat,
  toFixedAmount,
  usdFormatWithPercent,
  toUSDMarginFormat,
} from '@/helpers/utils';
import { computed, onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import { PositionSides } from '@/config/constants';
import bsvPrice from '@/compositions/bsvPrice';
import { floatList } from '@/helpers/floatList';
import useCalcPl from '@/compositions/useCalcPl';
import { useMarketPriceForMargin } from '@/compositions/useMarketPriceForMargin';
import { useBountyPrograms } from '@/compositions/useBountyPrograms';
import authMixin from '@/mixins/auth.mixin';
import useAuth from '@/compositions/useAuth';
import PanelInfoLabelValue from '@/components/ui/PanelInfoLabelValue';
import { useModals } from '@/modules/modals/api';
import ItemLabelValue from '@/components/ui/ItemLabelValue';

export default {
  name: 'FormPositionConfirm',
  mixins: [authMixin],
  components: {
    FormPositionConfirmPayment,
    ItemLabelValue,
    PanelInfoLabelValue,
  },
  props: {
    activeMarket: { required: true },
    orderType: { required: true },
    formData: { required: true },
    isBounty: Boolean,
    marginValueCustom: {
      required: true,
    },
  },
  emits: ['payment', 'error', 'onCloseOrderConfirm'],
  setup(props, { emit }) {
    const { showModal, modalsByName } = useModals();
    const auth = useAuth();
    const loyaltyReset = ref(true);
    const store = useStore();
    const { pl, pl_percent } = useCalcPl(
      props.orderType.toLowerCase(),
      props.activeMarket,
      props.formData.amountBtc,
      props.formData.entryPrice,
      props.formData.leverage
    );
    const { referralProgram } = useBountyPrograms();

    const tokensList = computed(() => {
      if (!auth.isFiorin.value) {
        return [];
      }

      return store.getters['fiorin/activeTokens'];
    });

    const activeTokenName = computed(() => {
      if (auth.isFiorin.value) {
        return (
          store.getters['formPosition/collateralToken/name'] ||
          tokensList.value[0]?.name
        );
      }
      return 'USD';
    });

    console.debug(
      '#formPositionConfirm activeTokenName ',
      activeTokenName.value
    );

    const accountBalance = computed(
      () => store.getters['user/getAccountBalance']
    );

    const activeTokenCurrency = computed(() => {
      if (auth.isFiorin.value) {
        return store.getters['formPosition/collateralToken/currency'];
      }

      return null;
    });

    const { marketPriceFromFiorinCurrency } =
      useMarketPriceForMargin(activeTokenCurrency);

    const prefixEntry = computed(() =>
      props.orderType.toLowerCase() === PositionSides.BUY ? 'ASK' : 'BID'
    );
    const prefixLiquidation = computed(() =>
      props.orderType.toLowerCase() === PositionSides.BUY ? 'BID' : 'ASK'
    );

    const settings = computed(() => {
      return store.getters['settings/settings'];
    });

    const plAmount = computed(() => pl.value);

    const plValue = computed(() => {
      return usdFormatWithPercent(pl.value, pl_percent.value);
    });

    onMounted(async () => {
      // loyaltyReset.value = true;
      console.log('tokensList', tokensList.value);
      if (auth.isFiorin.value) {
        await store.dispatch(
          'formPosition/collateralToken/setTokenId',
          tokensList.value[0]?.tokenId,
          {
            root: true,
          }
        );

        await store.dispatch(
          'volumeLimits/fetchLoyaltyFeeRate',
          props.activeMarket.id
        );
        await store.dispatch(
          'volumeLimits/fetchLoyaltyFeeInfo',
          props.activeMarket.id
        );
      }
      // loyaltyReset.value = true;
    });

    const markets = computed(() => store.getters['markets/markets']);

    const loyaltyFeeInfo = computed(
      () => store.getters['volumeLimits/loyaltyFeeInfo']
    );

    const loyaltyFeeRate = computed(
      () => store.getters['volumeLimits/loyaltyFeeRate']
    );

    const liquidationPrice = computed(() => {
      return props.orderType.toLowerCase() === PositionSides.BUY
        ? (props.formData.entryPrice || props.activeMarket.usdPrice) *
            (1 -
              80 / props.formData.leverage / 100 +
              0 / props.formData.amountBtc / bsvPrice())
        : (props.formData.entryPrice || props.activeMarket.usdPrice) *
            (1 +
              80 / props.formData.leverage / 100 -
              0 / props.formData.amountBtc / bsvPrice());
    });

    const entryPrice = computed(() => {
      if (props.formData.entryPrice) {
        return props.formData.entryPrice;
      }

      const current = markets.value.find(
        (item) =>
          `${item.ticker}/${item.base}` ===
          `${props.activeMarket.ticker}/${props.activeMarket.base}`
      );
      return props.orderType === PositionSides.SELL
        ? current.sell
        : current.buy;
    });

    const marginFiorinValue = computed(() => {
      const margin =
        +props.formData.amountBtc.replace(/ /g, '') /
        `${props.formData.leverage}`.replace(/ /g, '');
      return margin > 0.01 ? toUSDMarginFormat(margin, 2) : margin;
    });

    const marginFromTokenId = computed(() => {
      const bountyPos =
        props.isBounty &&
        accountBalance.value.bounty >= props.formData.marginValue;

      if (bountyPos) {
        return marginFiorinValue.value;
      }
      return marginFiorinValue.value / marketPriceFromFiorinCurrency.value;
    });

    const marginAmount = computed(() => {
      if (
        !props.formData ||
        !props.formData.amountBtc ||
        !props.formData.leverage ||
        !+props.formData.leverage
      ) {
        return '';
      }
      const marginBSV =
        +props.formData.amountBtc.replace(/ /g, '') /
        (auth.isFiorinMode.value ? 1 : bsvPrice()) /
        +`${props.formData.leverage}`.replace(/ /g, '');

      console.log('REFILL LOG margin after *', marginFromTokenId.value);
      // const openRandomPositionFlag = computed(
      //   () => store.getters['randomTrade/openRandomPositionFlag']
      // );

      // const bountyPos =
      //   openRandomPositionFlag.value ||
      //   (props.isBounty &&
      //     accountBalance.value.bounty >= props.formData.marginValue);

      if (auth.isFiorinMode.value) {
        // без символа, лейбл в marginAmountFormatted
        if (marginFromTokenId.value < 0.001) {
          return toCurrencyFormat(marginFromTokenId.value, null, 8);
        }
        return toFixedAmount(
          toCurrencyFormat(marginFromTokenId.value, null, null)
        );
        // return (
        //   toFixedAmount(toCurrencyFormat(marginFromTokenId.value, null, null)) +
        //   ` ${bountyPos ? 'USD-Bounty' : tokenIdName.value}`
        // );
      }

      return toCurrencyFormat(marginBSV);

      // return (
      //   toCurrencyFormat(marginBSV) +
      //   ` ${auth.currency.value}` +
      //   ` ($${toCurrencyFormat(bsvPrice() * marginBSV, null, 2)})`
      // );
    });

    if (auth.isFiorin.value) {
      store.commit(
        'formPosition/setMarginAmountUsd',
        Number.parseFloat(marginFiorinValue.value)
      );
    }

    const marginAmountFormatted = computed(() => {
      const openRandomPositionFlag = computed(
        () => store.getters['randomTrade/openRandomPositionFlag']
      );
      const bountyPos =
        openRandomPositionFlag.value ||
        (props.isBounty &&
          accountBalance.value.bounty >= props.formData.marginValue);

      if (auth.isFiorinMode.value) {
        return `${bountyPos ? 'USD-Bounty' : activeTokenName.value}`;
      }
      return 'BSV';
    });

    const config = computed(() => store.getters['user/wsConfig']);

    const isTelegram = computed(() =>
      Boolean(store.getters['telegram/instance'])
    );

    const onChooseCollateral = () => {
      if (auth.isFiorinMode.value && !props.isBounty) {
        showModal(modalsByName.chooseCollateral);
      }
    };

    const isNotStable = computed(() => {
      const labelCurr = marginAmountFormatted.value.toLowerCase();
      return (
        labelCurr.includes('eth') ||
        labelCurr.includes('btc') ||
        labelCurr.includes('bsv')
      );
    });

    return {
      isNotStable,
      wd: window,
      isTelegram,
      referralProgram,
      settings,
      liquidationPrice,
      toCurrencyFormat,
      entryPrice,
      marginAmount,
      marginAmountFormatted,
      config,
      emit,
      loyaltyFeeInfo,
      loyaltyFeeRate,
      accountBalance,
      floatList,
      pl,
      pl_percent,
      plValue,
      plAmount,
      loyaltyReset,
      prefixEntry,
      prefixLiquidation,
      onChooseCollateral,
    };
  },
};
</script>
<style lang="scss" scoped>
.form-position-confirm {
  display: flex;
  flex-direction: column;
  // min-height: calc(100vh - 250px);
  min-height: 492px;
  max-height: 492px;

  &__main-info {
    border-radius: 6px;
    overflow: hidden;
    width: calc(100% + 15px);
    margin-left: -6px;
  }

  &__stats-total {
    width: 100%;
    margin-left: -20px;
    margin-bottom: 25px;
    width: calc(100% + 40px);
    padding: 15px 20px;

    @media screen and (min-width: 1024px) {
      margin-left: -40px;
      width: calc(100% + 80px);
      padding: 15px 40px;
    }
  }

  .payment-container {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 50px;
    z-index: 1;

    &.standalone {
      bottom: 82px;
    }

    @media screen and (min-width: 1024px) {
      bottom: 0;
    }
  }

  &__stats-field {
    margin-bottom: 1px;

    &:last-child {
      margin-bottom: 0;
    }

    &.mt24 {
      margin-top: 24px;
    }

    &.mb-24 {
      margin-bottom: 24px;
    }
  }

  .loyalty-hint {
    margin-top: 24px;
    font-family: 'Gotham_Pro_Regular';
  }

  &__description {
    margin: 15px 0;
    font-family: Gotham_Pro_Medium;
  }
}
</style>
