import { connApp, getErrorDetails } from '@/helpers/signalR';
import { useModals } from '@/modules/modals/api';
import bsvPrice from '@/compositions/bsvPrice';
import {
  extendPositionWithStats,
  PositionTypes,
  createPositionEntity,
  createPositionLists,
  insertPositionToLists,
  getPositionType,
  calculateTotalsForPositionList,
  sortHistoryPositions,
  sortOpenedPositions,
} from '../helpers';
import {
  createComposition,
  findListByComposition,
} from '@/helpers/compositionGroup';
import notify from '@/plugins/notify';
import first from 'lodash/first';
import groupBy from 'lodash/groupBy';
import mapValues from 'lodash/mapValues';
import pull from 'lodash/pull';
import isEmpty from 'lodash/isEmpty';
import store from '@/store';

const types = {
  SET_CURRENT_POSITION: 'SET_CURRENT_POSITION',
  UPDATE_POSITION: 'UPDATE_POSITION',
  SET_ALL_POSITIONS: 'SET_ALL_POSITIONS',
  SET_STRUCTURE_POSITIONS: 'SET__STRUCTURE_POSITIONS',
  SET_SCROLLING_PAGE: 'SET_SCROLLING_PAGE',
  RESET_SCROLLING_PAGES: 'RESET_SCROLLING_PAGES',

  TOGGLE_FORM_POSITION: 'TOGGLE_FORM_POSITION',

  SET_OPENED_COUNT: 'SET_OPENED_COUNT',
  SET_PROPOSED_COUNT: 'SET_PROPOSED_COUNT',
  UPDATE_COUNT_POSITIONS: 'UPDATE_COUNT_POSITIONS',
  UPDATE_TOTALS: 'UPDATE_TOTALS',
  UPDATE_LOADING_STATUS: 'UPDATE_LOADING_STATUS',
  UPDATE_LOADING_SLEEP_STATUS: 'UPDATE_LOADING_SLEEP_STATUS',
  UPDATE_INCOMING_BALANCE: 'UPDATE_INCOMING_BALANCE',
  TOGGLE_FLAG_HALF_CLOSE: 'TOGGLE_FLAG_HALF_CLOSE',
};

const sortLists = (type, lists) => {
  if (type === PositionTypes.history) {
    sortHistoryPositions(lists);
  } else {
    sortOpenedPositions(lists);
  }
};

const { showModal, modalsByName } = useModals();

const state = {
  incomingBalance: {
    session: 0,
    positions: 0,
  },
  flagHalfClose: false,
  formPosition: false,
  positions: [],
  positionsById: {},
  positionsByType: {},
  positionsListsByType: {},
  positionsLoading: true,
  sleepLoading: false,
  currentPosition: null,
  openedCount: '',
  proposedCount: '',
  ready: false,
  updatedTotalWhenMarketsLoaded: false,
};
const getters = {
  ready: (state) => state.ready,
  updatedTotalWhenMarketsLoaded: (state) => state.updatedTotalWhenMarketsLoaded,

  flagHalfClose: (state) => state.flagHalfClose,
  getIncomingBalance: (state) => {
    return state.incomingBalance.session + state.incomingBalance.positions;
  },
  positionsLoading: (state) => state.positionsLoading,
  sleepLoading: (state, getters, rootState, rootGetters) => {
    return state.sleepLoading && rootGetters['auth/isLoggedIn'];
  },
  getGroupBy: (state) => state.formPosition,
  getFormPosition: (state) => state.formPosition,
  getOpenedCount: (state) => state.openedCount,
  getProposedCount: (state) => state.proposedCount,
  getPositionsListsByType: (state) => state.positionsListsByType,
  getPositionsByType: (state) => state.positionsByType,
  getPositionsById: (state) => state.positionsById,
  getPositions: (state) => state.positions,
  getActiveMarketPositions: (state, getters, rootState, rootGetters) => {
    const activeMarketId = rootGetters['markets/activeMarketId'];

    return state.positions
      .filter(({ state }) => ['opened', 'proposed'].includes(state))
      .filter(({ market_id }) => market_id === activeMarketId);
  },
  getAllPositions: (state) => {
    return state.positionsStructure;
  },
  getCurrentPosition: (state) => {
    return state.currentPosition
      ? state.positionsById[state.currentPosition.id]
      : {};
  },
  getMarketMaxAmount: (state, getters, rootState, rootGetters) => () => {
    const maxAmount = rootGetters['volumeLimits/groupActiveAmount'];

    return maxAmount;
    // const group =
    //   state.positionsListsByType.open &&
    //   state.positionsListsByType.open.find(
    //     (group) => group.data.market === market
    //   );

    // const groupPending =
    //   state.positionsListsByType.pending &&
    //   state.positionsListsByType.pending.find(
    //     (group) => group.data.market === market
    //   );

    // const sumOpened =
    //   (group &&
    //     group.list.reduce((prev, position) => {
    //       return prev + position.amount;
    //     }, 0)) ||
    //   0;

    // const sumPending =
    //   (groupPending &&
    //     groupPending.list.reduce((prev, position) => {
    //       return prev + position.amount;
    //     }, 0)) ||
    //   0;

    // return sumOpened + sumPending;
  },
};
const mutations = {
  [types.TOGGLE_FLAG_HALF_CLOSE](state, value) {
    state.flagHalfClose = value;
  },
  [types.UPDATE_INCOMING_BALANCE](state, value) {
    const { session, positions } = value;

    if (session === 0) {
      state.incomingBalance.session = 0;
      return;
    }

    if (positions === 0) {
      state.incomingBalance.positions = 0;
      return;
    }

    state.incomingBalance.session = session || 0;
    state.incomingBalance.positions = positions || 0;

    if (state.incomingBalance.positions < 0) {
      state.incomingBalance.positions = 0;
    }

    if (state.incomingBalance.session < 0) {
      state.incomingBalance.session = 0;
    }
  },
  [types.TOGGLE_FORM_POSITION](state, flag) {
    state.formPosition = flag;
  },
  [types.SET_CURRENT_POSITION](state, { position }) {
    state.currentPosition = position;
  },
  [types.UPDATE_COUNT_POSITIONS](state) {
    state.openedCount = groupBy(state.positions, 'opened').length;
    state.proposedCount = groupBy(state.positions, 'proposed').length;
  },
  [types.UPDATE_POSITION](state, { position, market }) {
    const addNewPosition = () => {
      const entity = createPositionEntity(position);
      const extendedEntity = extendPositionWithStats(
        entity,
        market,
        1 / bsvPrice()
      );

      let groupType = extendedEntity.type;
      if (['open', 'pending'].includes(extendedEntity.type)) {
        groupType = 'open';
      }

      state.positions.push(extendedEntity);
      state.positionsByType[groupType].push(extendedEntity);
      state.positionsById[extendedEntity.id] = extendedEntity;

      const lists = state.positionsListsByType[groupType];

      insertPositionToLists(lists, extendedEntity);
      sortLists(groupType, lists);
    };

    const currPosition = state.positionsById[position.id];

    if (!currPosition) {
      addNewPosition();
      return;
    }

    const type = getPositionType(position.state);

    let groupType = currPosition.type;
    if (['open', 'pending'].includes(currPosition.type)) {
      groupType = 'open';
    }

    if (type !== groupType) {
      pull(state.positions, currPosition);
      pull(state.positionsByType[groupType], currPosition);

      const lists = state.positionsListsByType[groupType];
      const composition = createComposition(currPosition, [
        'market',
        'side',
        'type',
      ]);
      const compositionList = findListByComposition(lists, composition);

      pull(compositionList.list, currPosition);

      if (isEmpty(compositionList.list)) {
        pull(lists, compositionList);
      }

      state.positionsById[currPosition.id] = null;

      addNewPosition();

      return;
    }

    const extendedPosition = extendPositionWithStats(
      { ...position },
      market,
      1 / bsvPrice()
    );

    Object.assign(currPosition, extendedPosition);
  },
  [types.SET_ALL_POSITIONS](state, positions) {
    state.positions = positions.map(createPositionEntity);

    const byId = groupBy(state.positions, 'id');
    const grouppedByType = groupBy(state.positions, 'type');

    state.positionsById = mapValues(byId, first);

    if (!state.positionsByType[PositionTypes.open]) {
      state.positionsByType[PositionTypes.open] = [];
    }

    state.positionsByType[PositionTypes.open] =
      (grouppedByType[PositionTypes.open] || []).concat(
        grouppedByType[PositionTypes.pending] || []
      ) || [];

    state.positionsByType[PositionTypes.pending] = [];
    // grouppedByType[PositionTypes.pending] || [];
    state.positionsByType[PositionTypes.history] =
      grouppedByType[PositionTypes.history] || [];

    for (const type in PositionTypes) {
      const lists = createPositionLists(state.positionsByType[type]);

      sortLists(type, lists);

      state.positionsListsByType[type] = lists;
      state.positionsLoading = false;
    }
  },
  [types.UPDATE_TOTALS](state) {
    const loadingMarkets = store.getters['markets/marketsLoading'];
    for (const type in PositionTypes) {
      const lists = state.positionsListsByType[type];

      lists.forEach((compositionList) => {
        const { list } = compositionList;
        const { pl, amount, closedSummary, entrySummary, marketId } =
          calculateTotalsForPositionList(list);

        compositionList.data.marketId = marketId;
        compositionList.data.pl = pl;
        compositionList.data.amount = amount;
        compositionList.data.closedSummary = closedSummary;
        compositionList.data.entrySummary = entrySummary;
      });
    }

    if (!loadingMarkets) {
      state.updatedTotalWhenMarketsLoaded = true;
    }
  },
  [types.SET_OPENED_COUNT](state, value) {
    state.openedCount = value;
  },
  [types.SET_PROPOSED_COUNT](state, value) {
    state.proposedCount = value;
  },
  [types.UPDATE_LOADING_STATUS](state, value) {
    state.positionsLoading = value;
  },
  [types.UPDATE_LOADING_SLEEP_STATUS](state, value) {
    state.sleepLoading = value;
  },

  setReady(state, value) {
    state.ready = value;
  },
};
const actions = {
  setReady({ commit }) {
    commit('setReady', true);
  },
  setCurrentPosition({ commit }, { position }) {
    commit(types.SET_CURRENT_POSITION, { position });
  },
  updateIncomingBalance({ commit }, value) {
    commit(types.UPDATE_INCOMING_BALANCE, value);
  },
  updatePosition({ commit, rootGetters }, position) {
    const market = rootGetters['markets/marketsByName'][position.market];

    commit(types.UPDATE_POSITION, { position, market });
    commit(types.UPDATE_COUNT_POSITIONS);
    commit(types.UPDATE_TOTALS);
  },
  setPositionsStructure({ state, rootGetters, commit }) {
    state.positions.forEach((position) => {
      const market = rootGetters['markets/marketsByName'][position.market];
      const stats = extendPositionWithStats(position, market, 1 / bsvPrice());

      Object.assign(position, stats);
    });

    commit(types.UPDATE_TOTALS);
  },

  async setAllPositions({ commit, dispatch }, positions) {
    commit(types.SET_ALL_POSITIONS, positions);
    commit(types.UPDATE_COUNT_POSITIONS);

    await dispatch('setPositionsStructure');
  },
  toggleFormPosition({ commit }, flag) {
    commit(types.TOGGLE_FORM_POSITION, flag);
  },
  onPositionConfirm({ state }) {
    return connApp.invokeImmediately(
      'CloseUserPosition',
      state.currentPosition.id
    );
  },
  onPositionCloseRequest({ getters, rootGetters }) {
    const sessionInfo = rootGetters['session/info'];
    const currentPosition = getters.getCurrentPosition;
    const rebateRatio = sessionInfo.rebateRatio;
    const isFiorin = rootGetters['auth/isFiorin'];

    const uiSettings = rootGetters['settings/uiSettings'];
    const settings = rootGetters['settings/settings'];
    const countLater = uiSettings.emailVerificationLater || 0;

    const actualPositionProfit =
      currentPosition.net_pl -
      rootGetters['volumeLimits/positionLoyaltyFeeValue'];

    if (
      rebateRatio &&
      actualPositionProfit > 0 &&
      currentPosition.state !== PositionTypes.proposed
    ) {
      showModal(modalsByName.profitRebate);
    } else {
      // check button later limit

      if (isFiorin) {
        if (settings.emailConfirmed) {
          // if confirm show close
          showModal(modalsByName.positionClose);
          return;
        }

        if (countLater >= 10) {
          // if not limit - show email only
          showModal(modalsByName.email);
          return;
        } else {
          // if not confirm and has limit - show email and show close
          showModal(modalsByName.email);
        }
      }
      showModal(modalsByName.positionClose);
    }
  },
  async toggleFlagHalfClose({ commit }, value) {
    commit(types.TOGGLE_FLAG_HALF_CLOSE, value);
  },
  async closeAllPositions() {
    await connApp.invokeImmediately('CloseManyPositions');
  },
  async changeUserPosition(
    _,
    { position_id, amount, take_profit, entry_price, stop_loss, update }
  ) {
    if (stop_loss > 80) {
      notify({ text: `Stop loss should be less than 80% loss`, type: 'info' });
      return;
    }

    if (update) {
      const dataToUpdate = { takeProfit: take_profit, stopLoss: stop_loss };

      let error;

      const res1 = await connApp
        .invokeImmediately('UpdateUserPosition', position_id, dataToUpdate)
        .catch((err) => {
          error = getErrorDetails(err);
        });
      console.log('change pos', res1, error, position_id, dataToUpdate);

      return error;
    }

    let msg = '';
    if (amount > 0) {
      msg = 'a' + amount;
    }

    if (take_profit != null && take_profit >= -1) {
      if (msg.length > 0) {
        msg += ',';
      }
      msg += 'p' + take_profit;
    }

    if (stop_loss != null && stop_loss <= 80) {
      if (msg.length > 0) {
        msg += ',';
      }
      msg += 's' + stop_loss;
    }

    if (entry_price > 0) {
      if (msg.length > 0) {
        msg += ',';
      }
      msg += 'e' + entry_price;
    }

    if (msg !== '') {
      const res1 = await connApp.invokeImmediately(
        'ChangeUserPosition',
        position_id,
        msg
      );
      console.log('change pos2', res1, position_id, msg);
    }
  },

  async onAuthenticated({ dispatch }) {
    try {
      console.debug('#onAuthenticated #positions #store');
      await dispatch('refreshUserPositions');
    } catch (e) {
      console.debugError('Error onAuthenticated #user #store', e);
      throw e;
    }
  },

  async refreshUserPositions({ commit }) {
    console.debug('#refreshUserData');

    commit(types.UPDATE_LOADING_STATUS, true);

    try {
      const activePositionsPromise = connApp.invokeWithRetry(
        'FilterUserPositions',
        {
          state: 'Open,Proposed',
          take: 200,
        }
      );
      const getHistoryPositionsPromise = connApp.invokeWithRetry(
        'FilterUserPositions',
        {
          state: 'History',
          take: 200,
        }
      );
      const getClosedPositionStatsPromise = connApp.invokeWithRetry(
        'GetClosedPositionsStats'
      );
      const getTradingStatsPromise = connApp.invokeWithRetry('GetTradingStats');

      await store.dispatch(
        'positions/setAllPositions',
        (await activePositionsPromise) ?? []
      );

      const openPositions =
        store.getters['positions/getPositionsByType'][PositionTypes.open];

      const pendingPositions =
        store.getters['positions/getPositionsByType'][PositionTypes.pending];

      const historyPositions = (await getHistoryPositionsPromise) ?? [];

      await store.dispatch('positions/setAllPositions', [
        ...openPositions,
        ...pendingPositions,
        ...historyPositions,
      ]);

      await store.commit(
        'session/SET_USER_TRADE_INFO_HISTORY',
        await getClosedPositionStatsPromise
      );

      store.commit('session/SET_USER_TRADE_INFO', await getTradingStatsPromise);

      await store.dispatch('positions/setReady');
    } catch (e) {
      console.debugError('Error #refreshUserData', e);
      throw e;
    } finally {
      commit(types.UPDATE_LOADING_STATUS, false);
      commit(types.UPDATE_LOADING_SLEEP_STATUS, false);
    }
  },
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
