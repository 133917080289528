<template>
  <Modal :stop-mobile-max-height="true" @close="$emit('close')">
    <div v-if="!showActive" class="announcements-wrap">
      <Card
        :title="$t('popups.announcement.list.title')"
        :body-class="'card-full'"
        class="card"
      >
        <div class="announcements">
          <div
            v-for="(item, ndx) in announcements"
            :key="ndx"
            v-html="formatAnnouncement(item)"
            class="announcements__item"
            @click="announceDetail = item"
          />
        </div>
      </Card>
    </div>
    <Modal
      v-if="announceDetail"
      :stop-mobile-max-height="true"
      @close="closeAnnounce"
    >
      <div class="announcements-wrap">
        <Card
          :title="$t('popups.announcement.title')"
          :body-class="'card-full'"
          class="card"
        >
          <div class="announcements child">
            <div
              v-html="formatAnnouncement(announceDetail)"
              class="announcements__item-info"
            />
          </div>
        </Card>
      </div>
    </Modal>
  </Modal>
</template>
<script>
import Modal from '@/modules/modals/Modal';
import Card from '@/components/ui/Card';
import { connApp } from '@/helpers/signalR';
import { onMounted, ref } from 'vue';

export default {
  components: { Modal, Card },
  props: {
    showActive: {
      type: Boolean,
      default: false,
    },
    activeAnnouncementsFromParent: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['close'],
  setup(props, { emit }) {
    const announcements = ref([]);
    const activeAnnouncements = ref([]);
    const announceDetail = ref(null);
    const activeQueue = ref(0);

    const closeAnnounce = () => {
      if (props.showActive) {
        announceDetail.value = activeAnnouncements.value[++activeQueue.value];
        if (!announceDetail.value) {
          announceDetail.value = null;
          emit('close');
        }
        return;
      }
      announceDetail.value = null;
    };

    onMounted(async () => {
      announcements.value = await connApp
        .invokeWithRetry('GetAnnouncements', { onlyActive: false })
        .catch(() => {
          emit('close');
        });
      if (announcements.value && announcements.value.length) {
        announcements.value = announcements.value.sort(
          (a, b) => new Date(b.dateEndUtc) - new Date(a.dateEndUtc)
        );
      }

      activeAnnouncements.value = await connApp
        .invokeWithRetry('GetAnnouncements', { onlyActive: true })
        .catch(() => {
          emit('close');
        });

      if (props.showActive) {
        activeAnnouncements.value = [].concat(
          props.activeAnnouncementsFromParent
        );
        // activeAnnouncements.value = announcements.value.slice(0, 3)

        if (!activeAnnouncements.value.length) {
          emit('close');
          return;
        }

        announceDetail.value = activeAnnouncements.value[activeQueue.value];
      }
    });

    const formatAnnouncement = (announcement) => {
      const date = announcement.dateEndUtc
        .split('T')[0]
        .split('-')
        .reverse()
        .map((a) => a.slice(a.length - 2, a.length))
        .join('.');

      if (announcement.html.startsWith('<p>')) {
        return announcement.html.replace('<p>', `<p>${date}&nbsp;`);
      }
      return `${date}&nbsp;${announcement.html}`;
    };

    return { announcements, announceDetail, formatAnnouncement, closeAnnounce };
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/styles/colors';
@import '@/assets/styles/base';

.announcements-wrap {
  .announcements {
    padding: 15px 20px;
    box-sizing: border-box;
    overflow-x: hidden;
    overflow-y: auto;
    height: 460px;

    &.child {
      height: auto;
      max-height: 300px;
      box-sizing: border-box;
      margin-bottom: 15px;
      overflow: auto;
    }

    &__item {
      font-family: Gotham_Pro_Regular;
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 1.125rem;
      overflow: hidden;
      max-width: 100%;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      cursor: pointer;
      display: inline-block;
    }

    &__item-info {
      font-family: Gotham_Pro_Regular;
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 1.125rem;
      word-wrap: break-word;
    }
  }

  .btn {
    margin-top: 30px;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }
}
</style>
