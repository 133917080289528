<template>
  <Modal :stop-mobile-max-height="true" @close="$emit(EVENTS.close)">
    <Card
      :title="$t('popups.liquidityFundraiser.transferLiqudity').toUpperCase()"
      class="card"
    >
      <div class="transfer-liquidity">
        <div v-if="isLoading" class="wraploader">
          <Loader />
        </div>
        <div v-else class="transfer-liquidity__list">
          <div class="transfer-liquidity__text">
            {{ $t('popups.liquidityFundraiser.transferLiqudityText') }}
          </div>
          <div class="transfer-liquidity__text">
            {{ $t('popups.liquidityFundraiser.confirmImportant') }}
          </div>
          <div
            v-for="(item, ndx) in transfersList.users"
            :key="ndx"
            class="transfer-liquidity__list-item"
          >
            <div class="paymail">{{ item.address }}</div>
            <Button
              :text="'Transfer'"
              type="secondary"
              size="medium"
              @click="onTransfer(item)"
              class="button"
            />
          </div>
        </div>
      </div>
    </Card>
    <div class="transfer-btn-wrap" />
    <Button
      :text="$t('close')"
      type="secondary"
      @click="$emit('close')"
      class="transfer-btn"
    />
    <TransferLiquidityConfirm
      v-if="selectedTransfer"
      :confirm-loading="confirmLoading"
      :info="selectedTransfer"
      :amount="transfersList.amount"
      @confirmTransfer="onConfirmTransfer"
      @close="onResetConfirm"
    />
  </Modal>
</template>
<script>
import Modal from '@/modules/modals/Modal';
import Card from '@/components/ui/Card';
import Button from '@/components/ui/Button';
import Loader from '@/components/ui/Loader';
import notify from '@/plugins/notify';
import TransferLiquidityConfirm from './TransferLiquidityConfirm';
import { connApp, getErrorDetails } from '@/helpers/signalR';

import { onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import useAuth from '@/compositions/useAuth';

const EVENTS = {
  close: 'close',
};

export default {
  components: { Modal, Card, Button, Loader, TransferLiquidityConfirm },
  props: {
    transfersList: {
      required: true,
    },
  },
  emits: [EVENTS.close, 'success'],
  setup(_, { emit }) {
    const auth = useAuth();
    const isLoading = ref(false);
    const confirmLoading = ref(false);
    const selectedTransfer = ref(null);
    const { t } = useI18n();

    const onTransfer = (item) => {
      selectedTransfer.value = item;
    };

    const onResetConfirm = () => {
      selectedTransfer.value = null;
    };

    const onConfirmTransfer = async () => {
      confirmLoading.value = true;
      let error;
      await connApp
        .invokeImmediately('TransferLiquidity', selectedTransfer.value.userId)
        .catch((err) => {
          error = getErrorDetails(err);
        });

      if (!error) {
        notify({
          text: t('popups.liquidityFundraiser.transferLiquditySuccess'),
          type: 'info',
        });
        emit('success');
        emit('close');
      } else {
        notify({ text: error.message, type: 'info' });
      }
      confirmLoading.value = false;
      selectedTransfer.value = null;
    };

    onMounted(async () => {
      if (!auth.isLoggedIn.value) {
        emit(EVENTS.close);
        return;
      }
    });

    return {
      EVENTS,
      isLoading,
      confirmLoading,
      selectedTransfer,
      onTransfer,
      onResetConfirm,
      onConfirmTransfer,
    };
  },
};
</script>
<style lang="scss" scoped>
.transfer-liquidity {
  width: 100%;
  position: relative;
  min-height: 300px;
  max-height: 375px;
  overflow-y: auto;
  box-sizing: border-box;
  font-family: Gotham_Pro_Regular;

  .button {
    max-width: 110px;
    min-height: 35px;
    height: 35px;
  }

  &__text {
    margin-bottom: 15px;
    line-height: 1.1;
  }

  &__list {
    display: flex;
    flex-direction: column;
    width: 100%;
    // padding-bottom: 40px;
  }

  &__list-item {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 10px;
  }

  .wraploader {
    width: 100%;
    display: flex;
    min-height: 160px;
    justify-content: center;
    align-items: center;
    position: relative;
  }
}

.transfer-btn-wrap {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  background: #fff;
  height: 51px;
  z-index: -1;
}

.transfer-btn {
  // margin-top: 30px;
  // position: absolute;
  // bottom: 0;
  // left: 0;
  width: 100%;
  opacity: 1;
}

body.dark {
  .transfer-btn-wrap {
    background: #121722;
  }
}
</style>
