const key = 'lastReleaseDate';

const releaseDateNumber = Number.parseInt(
  process.env.VUE_APP_RELEASE_DATE || '0'
);

export const releaseVersion = releaseDateNumber;

const releaseNumberFromHtml = Number.parseInt(
  document.body.attributes.getNamedItem('data-VUE_APP_RELEASE_DATE')?.value
);

export const releaseDatesMatch = releaseDateNumber === releaseNumberFromHtml;

export function startReleaseChecker() {
  if (!releaseDatesMatch) {
    console.error(
      'Release date number mismatch. Reload!',
      'FromJs:',
      releaseDateNumber,
      'FromHtml:',
      releaseNumberFromHtml
    );
  }

  // initial check
  {
    const getLocalDateTimeString = (date) => {
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();
      let hr = date.getHours();
      let min = date.getMinutes();
      let sec = date.getSeconds();
      let ms = date.getMilliseconds();

      return `${year}-${month}-${day} ${hr}:${min}:${sec}:${ms}`;
    };

    const args = [];

    let date = new Date(releaseDateNumber);

    const logo = `[.....    [..      [..  [.. ..  
[..   [..  [..   [..  [..    [..
[..    [..  [.. [..    [..      
[..    [..    [..        [..    
[..    [..  [.. [..         [.. 
[..   [..  [..   [..  [..    [..
[.....    [..      [..  [.. .. `;

    args.push('\n');
    args.push('---------------------------------------------------');
    args.push('');
    args.push(logo);
    args.push('\n');
    args.push('Release number: ');
    args.push(releaseDateNumber);
    args.push('');
    args.push('Release local date: ');
    args.push(getLocalDateTimeString(date));
    args.push('');
    args.push('Release UTC date: ');
    args.push(date.toUTCString());
    args.push('\n');

    check(
      () => {
        args.push(
          'Wow! Current is an old release... It should not ever happen... Reload!'
        );
        console.error(...args);
        window.location.reload();
      },
      () => {
        args.push(
          'Current release is newer than the last one. Setting new release date.'
        );
        setCurrentReleaseDate();
      },
      () => {
        args.push('Current release is the same as the last one.');
      }
    );

    args.push('\n');
    args.push('---------------------------------------------------');
    args.push('\n');
    args.push('#releaseChecker');

    console.log('%c' + args.join('\n'), 'font-size: 14px; font-weight: bold;');
  }

  // background check
  setInterval(() => {
    check(
      () => {
        console.error(
          'Reloading because it is outdated release!',
          '#releaseChecker'
        );
        window.location.reload();
      },
      () => {
        console.error(
          '---------------------------------------------------',
          '\n\n\n',
          'wow, current release is new! Was override with an old value?',
          '#releaseChecker',
          '\n\n\n',
          '---------------------------------------------------'
        );
      },
      () => {
        // no need to log
      }
    );
  }, 3_000);

  function check(onCurrentIsOldRelease, onCurrentIsNewRelease, onSameRelease) {
    const lastReleaseDateString = localStorage.getItem(key);
    if (lastReleaseDateString) {
      const lastReleaseDateNumber = Number.parseInt(lastReleaseDateString);
      if (releaseDateNumber > lastReleaseDateNumber) {
        onCurrentIsNewRelease();
      } else if (releaseDateNumber < lastReleaseDateNumber) {
        onCurrentIsOldRelease();
      } else {
        onSameRelease();
      }
    } else {
      console.log(
        'No last release date found. Setting current release date. #releaseChecker'
      );
      setCurrentReleaseDate();
    }
  }

  function setCurrentReleaseDate() {
    const s = releaseDateNumber.toString();
    localStorage.setItem(key, s);
    console.log('#releaseChecker Set current release:', s);
  }
}
