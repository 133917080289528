import { useStore } from 'vuex';
import { computed } from 'vue';

export default function useAuth() {
  const store = useStore();

  const isLoggedIn = computed(() => {
    return store.getters['auth/isLoggedIn'];
  });

  const isAuthFinished = computed(() => {
    return store.getters['auth/isAuthFinished'];
  });

  const userId = computed(() => {
    return store.getters['auth/userId'];
  });

  const provider = computed(() => {
    return store.getters['auth/provider'];
  });

  const dxsAuthData = computed(() => {
    return store.getters['auth/dxsAuthData'];
  });

  const isFiorin = computed(() => {
    return store.getters['auth/isFiorin'];
  });

  const isFiorinAsProxy = computed(() => {
    return store.getters['auth/isFiorinAsProxy'];
  });

  const isFiorinMode = computed(() => {
    return store.getters['auth/isFiorinMode'];
  });

  const isHandCash = computed(() => {
    return store.getters['auth/isHandCash'];
  });

  const isDotWallet = computed(() => {
    return store.getters['auth/isDotWallet'];
  });

  const accessToken = computed(() => {
    if (isLoggedIn.value) {
      return store.getters['auth/account']?.accessToken;
    }

    console.debug(
      'Accessing the $accessToken when not authenticated 2, from composition'
    );

    return null;
  });

  const currency = computed(() => {
    return isFiorinMode.value ? 'USD' : 'BSV';
  });

  return {
    isLoggedIn,
    isAuthFinished,
    userId,
    provider,
    dxsAuthData,
    isFiorin,
    isFiorinAsProxy,
    isFiorinMode,
    isHandCash,
    isDotWallet,
    accessToken,
    currency,
  };
}
