export const createIframeController = (id, hostUrl, onDelayed) => {
  if (!id || typeof id !== 'string') {
    console.error('id is not defined #FiorinConnector #connect', 'id:', id);
  }

  if (!hostUrl || typeof hostUrl !== 'string') {
    console.error(
      'hostUrl is not defined #FiorinConnector #connect',
      'hostUrl:',
      hostUrl
    );
  }

  if (!onDelayed || typeof onDelayed !== 'function') {
    console.error(
      'onDelayed is not defined #FiorinConnector #connect',
      'onDelayed:',
      onDelayed
    );
  }

  const frameElementId = `fiorinFrame_${id}`;
  const styleTemplate = `
    display: {display};
    left: 0;
    height: {height};
    width: {width}; 
    position: fixed;
    top: 0;
    z-index: 100100;
  `;
  const showStyles = styleTemplate
    .replace('{display}', 'block')
    .replace('{height}', '100%')
    .replace('{width}', '100%');
  const closeStyles = styleTemplate
    .replace('{display}', 'none')
    .replace('{height}', '0')
    .replace('{width}', '0');

  let frame = frames[id];
  if (!frame) {
    console.debug(
      'creating frame #FiorinConnector #connect, id:',
      id,
      'hostUrl:',
      hostUrl
    );

    frame = loadIframe((frame) => {
      frame.setAttribute('id', frameElementId);
      frame.setAttribute('src', hostUrl);
      frame.setAttribute('name', 'fiorinFrameName' + id);
      frame.setAttribute('frameBorder', '0');
      frame.setAttribute('allow', 'clipboard-read; clipboard-write');
      frame.setAttribute('style', closeStyles);
    });
  } else {
    console.debug(
      'frame already exists #FiorinConnector #connect, id:' + id,
      'hostUrl:',
      hostUrl
    );
  }

  const closeFrame = () => {
    frame.setAttribute('style', closeStyles);
  };

  const showFrame = (from) => {
    console.debug(`#showFrame #FiorinConnector`, from);
    frame.setAttribute('style', showStyles);
  };

  function removeFiorinFrame() {
    const frame = document.getElementById(frameElementId);
    if (frame) {
      frame.parentNode.removeChild(frame);
      return true;
    }

    return false;
  }

  function loadIframe(init) {
    const iframe = document.createElement('iframe');
    init(iframe);

    // Create a timeout to handle loading failures
    const timeout = setInterval(() => {
      console.warn('Iframe is taking too long to load. #DXS #loadIframe');
    }, 10000);

    const timeoutNotify = setInterval(() => {
      onDelayed();
    }, 20_000);

    iframe.onload = () => {
      console.debug(
        'Iframe loaded successfully. #loadIframe #iframeController'
      );
      clearTimeout(timeoutNotify);
      clearTimeout(timeout);
    };

    // Handle iframe error event (though it may not always fire)
    iframe.onerror = (error) => {
      clearTimeout(timeoutNotify);
      clearTimeout(timeout);
      console.error(
        'Iframe failed to load (onerror event). #DXS #loadIframe, error:',
        error
      );
    };

    document.body.appendChild(iframe);
    return iframe;
  }

  return {
    frame,
    closeFrame,
    showFrame,
    removeFiorinFrame,
  };
};
