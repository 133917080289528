let lastRequestId = Math.floor(Math.random() * 1000);

export const setupIframeConnection = (
  iframe,
  frameHost,
  label,
  onDelayed,
  onUserEventAsync
) => {
  if (typeof iframe === 'undefined' || iframe === null) {
    console.error(label, 'iframe is not defined #iframeConnection');
  }

  if (!frameHost || typeof frameHost !== 'string') {
    console.error(
      label,
      'frameHost is not defined #iframeConnection',
      'frameHost:',
      frameHost
    );
  }

  if (!label || typeof label !== 'string') {
    console.error(
      label,
      'label is not defined #iframeConnection',
      'label:',
      label
    );
  }

  if (!onDelayed || typeof onDelayed !== 'function') {
    console.error(
      label,
      'onDelayed is not defined #iframeConnection',
      'onDelayed:',
      onDelayed
    );
  }

  let waitingForEvents = {};

  const sendMessageToIframeAsync = (eventName, payload) => {
    if (!eventName) {
      throw new Error('eventName is required');
    }

    const requestId = ++lastRequestId;

    let promise = new Promise((resolve, reject) => {
      waitingForEvents[requestId] = { resolve, reject, eventName };
      let data = { requestId, event: eventName, payload };
      console.debug(label, `#sendMessageToIframeAsync data:`, data);
      // console.debug(label, `#iframe`, iframe, iframe.contentWindow.postMessage);
      iframe.contentWindow.postMessage(data, frameHost);
    });

    const intervalId = setInterval(() => {
      if (!waitingForEvents) {
        console.debug(
          label,
          'from timout check: waitingForEvents is destroyed, its OK #sendMessageToIframeAsync'
        );
        clearInterval(intervalId);
      }

      let obj = waitingForEvents[requestId];
      if (obj) {
        console.debugError(
          label,
          `Promise is not resolved in 30 sec, still waiting. #sendMessageToIframeAsync  #timeout eventName: `,
          obj
        );

        onDelayed();
        // [Vadim] As discussed with Oleg, we should not reject the promise if fiorin ins not responding
        // obj.reject('timeout');
        // delete waitingForEvents[requestId];
      } else {
        clearInterval(intervalId);
        console.debug(
          label,
          `from timout check: resolved event promise: #sendMessageToIframeAsync  #timeout eventName: ${requestId}`
        );
      }
    }, 30_000);

    return promise;
  };

  const onEventReceived = (eventObj, success, result) => {
    if (!waitingForEvents) {
      console.warn(
        label,
        'waitingForEvents is destroyed. Its ok. Mostly #onEventReceived, eventObj:',
        eventObj
      );
      return;
    }

    if (!eventObj) {
      console.debug(
        label,
        'Empty eventPayload, #onEventReceived #FiorinConnector'
      );
      return;
    }

    const requestId = eventObj.requestId;
    if (!requestId) {
      return;
    }

    const obj = waitingForEvents[requestId];
    if (obj) {
      // console.debug(`onEventReceived found for requestId: ${requestId}, #FiorinConnector promiseData:`, obj);
      if (success) {
        obj.resolve(result);
      } else {
        obj.reject(result);
      }
      delete waitingForEvents[requestId];
    } else {
      console.debugError(
        label,
        `#onEventReceived, requestId is not awaited or already resolved, requestId:`,
        requestId
      );
    }
  };
  const onMessageInternal = async (event) => {
    if (event.origin !== frameHost) {
      if (event.origin !== document.location.origin) {
        console.debug(
          label,
          `#onMessage, wrong origin: ${event.origin} expected: ${frameHost}`,
          event
        );
      }
      return;
    }

    const data = event.data;
    if (!data) {
      console.debugError(label, 'Empty eventPayload, #onMessageHandler');
      return;
    }

    try {
      let result = await onUserEventAsync(event);
      if (typeof result === 'undefined') {
        result = data;
      }
      onEventReceived(data, true, result);
    } catch (e) {
      console.debugError(
        label,
        '#onUserEventAsync error',
        e.message,
        e,
        e.stack
      );
      onEventReceived(data, false, e);
    }
  };

  window.addEventListener('message', onMessageInternal);

  return {
    sendMessageAsync: (eventName, payload) =>
      sendMessageToIframeAsync(eventName, payload),

    disconnect: () => {
      window.removeEventListener('message', onMessageInternal);

      const copyWaitingForEvents = waitingForEvents;
      waitingForEvents = null;
      console.debug('disconnect #FiorinConnector #iframeConnection');

      for (const key in copyWaitingForEvents) {
        const promise = copyWaitingForEvents[key];
        if (promise.reject) {
          console.debug('Rejecting a promise when destroy.., key:', key);
          promise.reject('destroyed: ' + key);
        }
      }
    },
  };
};
