<template>
  <form
    @submit.prevent
    :class="{
      empty: false,
      windows: isWindows,
    }"
    class="form-position-simple"
  >
    <Selector
      description="SELECT LEVERAGE"
      description-input="EXPAND"
      description-secodary="SELECT % OF MAX AMOUNT"
      :update-value="updateValue"
      :optional-btn="optionalLeverageBtnTitle"
      :optional-btn-secondary="optionalAmountBtnTitle"
      :items="leverageItems"
      :secondary-items="percentAmountItems"
      :initial-slider-value="leverage"
      :initial-input-value="amountBtc"
      :initial-percent-amount="percentSelectorAmount"
      :input-title-label="
        !enableToOpenPosition
          ? isFiorinNullableBalance
            ? 'Wallet depleted'
            : $t('popups.marketInactive.limitReached')
          : 'AMOUNT, USD'
      "
      lefted
      fixed-arrow-width
      show-input-first
      not-reset-input-after-select
      :max-value="+maxBalance"
      :disabled="!activeMarket || !activeMarket.name || !enableToOpenPosition"
      @select="onSelectLeverage"
      @selectSecondary="onSelectPercentMaxAmount"
      @changeInput="onChangeSelectorInput"
      @descriptionClick="onExpand"
    />
  </form>
</template>
<script>
import { useStore } from 'vuex';
import { ref, computed, watch, onMounted, nextTick } from 'vue';
import { toCurrencyFormat } from '@/helpers/utils';
import notify from '@/plugins/notify';
import { useI18n } from 'vue-i18n';
import { DEFAULT_MARKET_BSV_USD } from '@/config/constants';

const LEVERAGE_LOCAL_KEY = 'leverage_';
import useMaxBalance from '@/compositions/useMaxBalance';
import { POSITION_OPEN_MIN_VAL } from '@/helpers/enums';
import Selector from '@/components/ui/Selector';
import { updateAndSetUserSettings } from '@/modules/settings/api';
import { toFixedAmount } from '@/helpers/utils';
import { useFiorinBalanceNull } from '@/compositions/useFiorinBalanceNull';
import authMixin from '@/mixins/auth.mixin';
import useAuth from '@/compositions/useAuth';

export default {
  name: 'FormPositionSimpleForm',
  mixins: [authMixin],
  components: { Selector },
  props: {
    initialLeverage: { required: true },
    activeMarket: { required: true },
    initialAmount: { required: true },
  },
  setup(props, { emit }) {
    const auth = useAuth();
    const { t } = useI18n();
    const { isFiorinNullableBalance } = useFiorinBalanceNull();

    const store = useStore();
    const updateValue = ref('');
    const isWindows = ref(navigator.appVersion.indexOf('Win') != -1);

    const amountBtc = ref('');
    const marginValue = ref('');
    const takeProfit = ref('');
    const entryPrice = ref('');
    const stopLoss = ref('');

    const percentSelectorAmount = ref('');

    const accountBalance = computed(
      () => store.getters['user/getAccountBalance']
    );

    const leverageItems = computed(() => {
      const max = +props.activeMarket.max_leverage;

      const items = [{ title: 1 }];

      if (max <= 10) {
        for (let i = 2; i <= max; i++) {
          items.push({
            title: i,
          });
        }
        return items;
      }

      for (let i = 2; i < 11; i++) {
        items.push({
          title:
            i === 10
              ? max
              : Math.round(+items[items.length - 1].title + max / 10),
        });
      }

      return items;
    });

    const percentAmountItems = computed(() => {
      const arr = [10, 25, 50, 70, 80, 100];
      const result = [];
      for (let i = 0; i <= arr.length - 1; i++) {
        result.push({
          title: arr[i],
        });
      }
      return result;
    });

    const marketsById = computed(() => store.getters['markets/marketsByName']);

    const bsvPrice = computed(() => {
      const market = marketsById.value[DEFAULT_MARKET_BSV_USD];

      return (market && market.usdPrice) || 1;
    });

    const getLeverageLocalKey = () =>
      `${LEVERAGE_LOCAL_KEY}${props.activeMarket.name}`;

    const leverage = ref(
      props.initialLeverage ||
        (props.activeMarket && props.activeMarket.max_leverage) ||
        1
    );

    // console.debug(
    //   '#formPositionSimpleForm',
    //   '#leverage',
    //   '#setup',
    //   '\n',
    //   'leverage.value',
    //   leverage.value,
    //   '\n',
    //   'props.initialLeverage',
    //   props.initialLeverage,
    //   '\n',
    //   'props.activeMarket.max_leverage',
    //   props.activeMarket?.max_leverage
    // );

    const uiSettings = computed(() => {
      return store.getters['settings/uiSettings'];
    });

    const setInitialLeverage = (/*from*/) => {
      const cachedLeverageLocal = localStorage.getItem(getLeverageLocalKey());
      const cachedLeverageServer = uiSettings.value[getLeverageLocalKey()];

      leverage.value =
        // [Vadim] disabled this line, since it breaks the result
        // localStorage.getItem('cacheLeverage') ||
        cachedLeverageLocal ||
        cachedLeverageServer ||
        props.initialLeverage ||
        props.activeMarket.max_leverage;

      if (leverage.value > props.activeMarket.max_leverage) {
        leverage.value = props.activeMarket.max_leverage;
      }

      //show all values:
      // console.debug(
      //   '#formPositionSimpleForm',
      //   '#setInitialLeverage',
      //   '#leverage',
      //   '\n---',
      //   'from: ' + from,
      //   '\n---',
      //   'leverage.value (result)',
      //   leverage.value,
      //   '\n',
      //   'props.initialLeverage',
      //   props.initialLeverage,
      //   '\n',
      //   'localStorage.getItem(cacheLeverage)',
      //   localStorage.getItem('cacheLeverage'),
      //   '\n',
      //   'cachedLeverageLocal',
      //   cachedLeverageLocal,
      //   '\n',
      //   'cachedLeverageServer',
      //   cachedLeverageServer,
      //   '\n',
      //   'props.activeMarket.max_leverage',
      //   props.activeMarket.max_leverage
      // );
    };

    setInitialLeverage('setup');

    onMounted(() => {
      setInitialLeverage('mounted');
      sendFormData();
    });

    watch(
      () => props.activeMarket.name,
      () => {
        if (props.activeMarket.name) {
          const initialAmount = localStorage.getItem(
            `amount${props.activeMarket.name}`
          );
          percentSelectorAmount.value = '';

          setInitialLeverage('watch props.activeMarket.name');
          if (initialAmount) {
            amountBtc.value = initialAmount;
            updateValue.value = initialAmount;
            onChangeSelectorInput(initialAmount);
          } else {
            amountBtc.value = '';
            updateValue.value = '';
          }
          sendFormData();
        }
      }
    );

    const sendFormData = () => {
      emit('changeNewOrderForm', {
        amountBtc: amountBtc.value,
        marginValue:
          amountBtc.value /
          (leverage.value * (auth.isFiorin.value ? 1 : bsvPrice.value)),
        takeProfit: takeProfit.value,
        entryPrice: entryPrice.value,
        leverage: leverage.value,
        stopLoss: stopLoss.value,
      });
    };

    const setLeverage = () => {
      //const initialValue = leverage.value.toString();
      if (
        !leverage.value ||
        +leverage.value > props.activeMarket.max_leverage
      ) {
        leverage.value = props.activeMarket.max_leverage;
      }
      if (leverage.value < 1) {
        leverage.value = 1;
        // this.$refs.leverage.$refs.input.value = 1
      }

      // console.debug(
      //   '#formPositionSimpleForm',
      //   '#setLeverage',
      //   '#leverage',
      //   '\n',
      //   'leverage.value (result)',
      //   leverage.value,
      //   '\n',
      //   'initialValue:',
      //   initialValue,
      //   '\n',
      //   'props.activeMarket.max_leverage',
      //   props.activeMarket.max_leverage
      // );
    };

    const enableToOpenPosition = computed(() => {
      return useMaxBalance(
        leverage.value,
        amountBtc.value,
        auth.isFiorinMode.value,
        t
      ).enableToOpenPosition.value;
    });

    const maxBalance = computed(() => {
      return useMaxBalance(
        leverage.value,
        amountBtc.value,
        auth.isFiorinMode.value,
        t
      ).maxBalance.value;
    });

    const maxWalletValue = computed(() => {
      return useMaxBalance(
        leverage.value,
        amountBtc.value,
        auth.isFiorinMode.value,
        t
      ).maxWalletValue.value;
    });

    const onChange = (value, opts = {}) => {
      if (+value > +maxBalance.value) {
        if (auth.isFiorin.value) {
          // showModal(modalsByName.smallAmount);
          const input = document.querySelector(
            '.new-version.selectorLiquidityInput input'
          );
          if (input) {
            input.blur();
          }
        }
        const toastText =
          maxWalletValue.value < POSITION_OPEN_MIN_VAL
            ? t('order.new.toasts.depleted', {
                provider: auth.provider.value,
              })
            : t('order.new.toasts.maxLimit');

        if (opts.initialMode) {
          amountBtc.value = '';
          return;
        }
        onChange(maxBalance.value);

        if (hideToast.value) {
          notify({
            text: toastText,
            type: 'info',
          });
        }
      } else {
        amountBtc.value = value;
      }

      setLeverage();
      marginValue.value = toCurrencyFormat(
        amountBtc.value /
          (leverage.value * (auth.isFiorin.value ? 1 : bsvPrice.value))
      );
      sendFormData();

      /*if (!getAccountBalance.value.available && getAccountBalance.value.available !== 0 && this.userId) {
        this.toggleUserFormActive(true)
      }*/
    };

    if (props.initialAmount) {
      onChange(props.initialAmount, { initialMode: true });
    }

    const isNoteClickable = computed(
      () => true // !amountBtc.value || !amountBtc.value.length
    );

    const optionalLeverageBtnTitle = computed(() => {
      return `${leverage.value}X`;
    });

    const optionalAmountBtnTitle = computed(() => {
      const val1 = amountBtc.value;
      const val2 = toFixedAmount(
        (maxBalance.value / 100) * percentSelectorAmount.value
      );

      if (!percentSelectorAmount.value || (amountBtc.value && val1 != val2)) {
        return `X%`;
      }
      return `${
        percentSelectorAmount.value == 100
          ? 'MAX'
          : percentSelectorAmount.value + '%'
      }`;
    });

    const hideToast = ref(false);

    const onSelectLeverage = async (val) => {
      //const initialValue = leverage.value.toString();
      leverage.value = val?.title || leverage.value;
      // console.debug(
      //   '#formPositionSimpleForm',
      //   '#onSelectLeverage',
      //   '#leverage',
      //   '\n',
      //   'leverage.value',
      //   leverage.value,
      //   '\n',
      //   'initialValue:',
      //   initialValue,
      //   '\n',
      //   'val:',
      //   val
      // );

      onChange(amountBtc.value);
      console.log('maxBalance', maxBalance.value);
      // sendFormData();
      // localStorage.setItem(getLeverageLocalKey(), leverage.value);

      localStorage.setItem(getLeverageLocalKey(), leverage.value);
      // update on server
      await updateAndSetUserSettings({
        uiSettings: {
          [getLeverageLocalKey()]: leverage.value,
        },
      });
      hideToast.value = true;
      nextTick(() => {
        updateValue.value = amountBtc.value;
        onChange(amountBtc.value);
        setTimeout(() => {
          hideToast.value = false;
        }, 1500);
      });
    };

    const onSelectPercentMaxAmount = (val) => {
      if (localStorage.getItem('stopAutosetAmount')) {
        // not set after open positions
        return;
      }

      percentSelectorAmount.value =
        val?.title || val?.value?.title || percentSelectorAmount.value;

      if (maxBalance.value) {
        updateValue.value = ' ';
        setTimeout(() => {
          updateValue.value =
            toFixedAmount(
              (maxBalance.value / 100) * percentSelectorAmount.value
            ) + '';
          amountBtc.value = updateValue.value;
          onChange(amountBtc.value);
        }, 0);
      }
    };

    const onChangeSelectorInput = (val) => {
      amountBtc.value = val;
      onChange(amountBtc.value);
      sendFormData();

      // percentSelectorAmount.value = '';
      // percentSelectorAmount.value = '';
    };

    const onExpand = () => {
      emit('expand', {
        value: leverage.value,
        percentSelectorAmount:
          optionalAmountBtnTitle.value === 'X%'
            ? ''
            : optionalAmountBtnTitle.value,
      });
    };

    const addFunds = () => {
      return store.dispatch('fiorin/viewDeposit');
    };

    return {
      isFiorinNullableBalance,
      isWindows,
      addFunds,
      onExpand,
      onChangeSelectorInput,
      onSelectLeverage,
      optionalLeverageBtnTitle,
      optionalAmountBtnTitle,
      maxBalance,
      amountBtc,
      updateValue,
      isNoteClickable,
      enableToOpenPosition,
      onChange,
      leverage,
      leverageItems,
      percentAmountItems,
      onSelectPercentMaxAmount,
      percentSelectorAmount,
      accountBalance,
    };
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/styles/base';
@import '@/assets/styles/colors';

.form-position-simple {
  width: 100%;
  padding: 0 16px 0 1.344rem;
  min-height: 110px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1.219rem auto 10px;
  box-sizing: border-box;

  &.windows {
    margin-top: 1.25rem;
  }

  &.empty {
    padding: 0 16px;
  }
}
</style>
