import { WalletNames } from '@/wallets/common';
import HandcashConnector from '@/wallets/Handcash';
import { v4 as uuidv4 } from 'uuid';
import store from '@/store';

let _connector = null;
const getInitialState = () => ({
  profile: null,
  balance: {
    amountBsv: 0,
    amountUsd: 0,
  },
});

const assertHandcash = () => {
  const provider = store.getters['auth/provider'];
  if (provider !== WalletNames.handCash) {
    console.debugError(
      'Accessing the Handcash-related fields when not authenticated  or using other wallet #wallet #getters #ifHandcash',
      'provider:',
      provider
    );

    throw new Error('Not Handcash');
  }
};

function getAuthTokenFromUrl() {
  let params = new URLSearchParams(window.location.search);
  let accessToken = params.get('authToken');
  return accessToken;
}

const getters = {
  paymail: (state) => {
    assertHandcash();
    const paymail = state.profile?.publicProfile?.paymail;
    return paymail;
  },
  email: (state) => {
    assertHandcash();
    return state.profile?.privateProfile?.email;
  },
  phoneNumber: (state) => {
    assertHandcash();
    return state.profile?.privateProfile?.phoneNumber;
  },
  fullName: (state) => {
    assertHandcash();
    let hcuserName = state.profile?.publicProfile?.paymail
      .replace('.io', '')
      .toLowerCase();

    return hcuserName;
  },
  userName: (state) => {
    assertHandcash();
    let hcuserName = state.profile?.publicProfile?.paymail
      .replace('.io', '')
      .toLowerCase();

    let result = hcuserName?.split('@')[0]?.toLowerCase();
    return result;
  },

  amountBsv: (state) => {
    assertHandcash();
    return state.balance?.amountBsv;
  },

  amountUsd: (state) => {
    assertHandcash();
    return state.balance?.amountUsd;
  },
};

const mutations = {
  setProfile(state, profile) {
    state.profile = profile;
  },

  setBalance(state, { amountBsv, amountUsd }) {
    if (typeof amountBsv !== 'number') {
      throw new Error('Invalid amountBsv #handcache #setBalance');
    }
    if (typeof amountUsd !== 'number') {
      throw new Error('Invalid amountUsd #handcache #setBalance');
    }
    state.balance = {
      amountBsv,
      amountUsd,
    };
  },

  clear(state) {
    state.profile = null;
    state.balance = {};
  },
};
let actions = {
  async initAuth({ dispatch }, account) {
    if (account.provider !== WalletNames.handCash) {
      throw new Error(
        'Invalid provider. #handcache #initAuth Expected HandCash, got ' +
          account.provider
      );
    }

    if (!account.accessToken) {
      throw new Error('Invalid access token #handcache #initAuth');
    }

    if (!account.id) {
      throw new Error('Invalid account id #handcache #switchAccount');
    }

    await dispatch('authenticate', account);
  },

  async startLogin() {
    // do not save the connector
    const handCashWallet = new HandcashConnector({});
    await handCashWallet.connect();
  },

  async sendHandCashTx({ dispatch }, payload) {
    assertHandcash();
    const result = await _connector.sendHandCashTx(payload);

    if (result.transactionId) {
      dispatch('fetchBalance');
    }

    return result;
  },

  canConnect() {
    if (!getAuthTokenFromUrl()) {
      console.debug(
        '#auth  connectPendingProvider #handCash, has no token in url, cannot start'
      );
      return false;
    }

    if (!document.referrer.startsWith('https://market.handcash.io')) {
      console.debugError(
        '#auth connectPendingProvider #handCash, referrer is not handcash, do not start'
      );
      return false;
    }
    return true;
  },

  async completeConnect({ dispatch }) {
    const accessToken = getAuthTokenFromUrl();
    console.debug(
      '#auth  connectPendingProvider #handCash accessToken:',
      accessToken
    );

    return await dispatch('authenticate', { accessToken });
  },

  async authenticate({ commit }, { id, accessToken }) {
    try {
      const handCashWallet = new HandcashConnector({
        id: id,
        accessToken: accessToken,
      });

      await handCashWallet.connect();

      const profilePromise = handCashWallet.fetchProfile();
      const balancePromise = handCashWallet.fetchBalance();

      const [profile, balance] = await Promise.all([
        profilePromise,
        balancePromise,
      ]);

      commit('setProfile', profile);
      commit('setBalance', balance);

      const account = {
        id: handCashWallet.id || uuidv4(),
        provider: WalletNames.handCash,
        accessToken: handCashWallet.accessToken,
        userName: profile.publicProfile.paymail,
      };

      console.debug('#auth  connectHandCache #account:', account);

      await store.dispatch('auth/onAuthenticated', account, {
        root: true,
      });

      _connector = handCashWallet;

      return true;
    } catch (e) {
      console.error('Error initializing HandCash', e);
      return false;
    }
  },

  async fetchBalance({ commit }) {
    const balance = await _connector.fetchBalance();
    commit('setBalance', {
      amountBsv: balance.amountBsv,
      amountUsd: balance.amountUsd,
    });
  },

  async logout({ dispatch }) {
    await dispatch('auth/onLogout', null, { root: true });
  },

  async clear({ commit }) {
    commit('clear');
    if (_connector) {
      _connector = null;
    }
  },
};

export default {
  namespaced: true,
  state: getInitialState,
  getters,
  mutations,
  actions,
};
