import { connApp, connMarkets } from '@/helpers/signalR';
import notify, { removeNotification } from '@/plugins/notify';
import { computed, watch } from 'vue';
import { emitEvents } from '@/helpers/signalR/emitEvents';
import { OneWeekMs } from '@/helpers/timeConstants';

let abnormalConnectionNotificationId = null;

const showAbnormalConnection = async () => {
  console.debug('showAbnormalConnection');

  if (abnormalConnectionNotificationId) {
    console.debug('Already shown showAbnormalConnection');
    return;
  }

  abnormalConnectionNotificationId = await notify({
    text: 'Abnormal connection; wait or try another network',
    type: 'error',
    duration: OneWeekMs,
  });
};

const hideAbnormalConnection = async (showConnectionEstablished) => {
  if (abnormalConnectionNotificationId) {
    await removeNotification(abnormalConnectionNotificationId);
    abnormalConnectionNotificationId = null;

    console.debug(
      '#hideAbnormalConnection #watchServerStreamingIssues showConnectionEstablished:',
      showConnectionEstablished
    );

    if (showConnectionEstablished) {
      await notify({
        text: 'Connection established',
        type: 'info',
        duration: 5_000,
      });
    }
  }
};

const state = {
  isEnsureConnectionProcessing: false,
  unresolvedLongInvocationCount: 0,
  connAppIsConnected: true,
  connMarketsIsConnected: true,
};

const getters = {
  isEnsureConnectionProcessing: (state) => state.isEnsureConnectionProcessing,
  unresolvedLongInvocationCount: (state) => state.unresolvedLongInvocationCount,
  connAppIsConnected: (state) => state.connAppIsConnected,
  connMarketsIsConnected: (state) => state.connMarketsIsConnected,
};

const mutations = {
  setIsEnsureConnectionProcessing(state, value) {
    state.isEnsureConnectionProcessing = value;
  },

  incrementUnresolvedLongInvocationCount(state) {
    state.unresolvedLongInvocationCount++;
  },

  decrementUnresolvedLongInvocationCount(state) {
    state.unresolvedLongInvocationCount--;
  },

  setConnAppIsConnected(state, value) {
    state.connAppIsConnected = value;
  },

  setConnMarketsIsConnected(state, value) {
    state.connMarketsIsConnected = value;
  },
};

const actions = {
  async init({ dispatch }) {
    await dispatch('subscribeToNetworkEvents');
    await dispatch('watchServerStreamingIssues');
  },

  async ensureConnectionsAreActive({ commit }) {
    commit('setIsEnsureConnectionProcessing', true);

    // ths timeout is needed to prevent the toaster from being shown when the connection is not restored quickly
    setTimeout(() => {
      commit('setIsEnsureConnectionProcessing', false);
    }, 5_000);

    try {
      await Promise.all([
        connApp.ensureConnected(),
        connMarkets.ensureConnected(),
      ]);
    } catch (error) {
      console.error('Error when recover connection #network', error);
    } finally {
      commit('setIsEnsureConnectionProcessing', false);
    }
  },

  async watchServerStreamingIssues({ rootGetters, getters }) {
    // use it as computed property here, since then it will be from other storage instance so will not work in getters
    const isTickerStarving = computed(() => {
      return rootGetters['markets/tickerStarving'];
    });

    const isUserStatsStarving = computed(() => {
      return rootGetters['session/userStatsStarving'];
    });

    const isVisible = computed(() => {
      return rootGetters['uiVisibility/isVisible'];
    });
    const isNetworkFailure = computed(() => {
      return (
        !getters.connAppIsConnected ||
        !getters.connMarketsIsConnected ||
        getters.unresolvedLongInvocationCount > 0 ||
        isTickerStarving.value
      );
    });

    let timerId = null;

    watch(
      () =>
        !getters.isEnsureConnectionProcessing &&
        isNetworkFailure.value &&
        isVisible.value,
      async (value) => {
        const isEnsureConnectionProcessing =
          getters.isEnsureConnectionProcessing;
        console.debug(
          '#watchServerStreamingIssues set',
          '\n',
          'showLostConnection:',
          value,
          '\n',
          '#network',
          '\n',
          'connAppIsConnected:',
          getters.connAppIsConnected,
          '\n',
          'connMarketsIsConnected:',
          getters.connMarketsIsConnected,
          '\n',
          'isTickerStarving:',
          isTickerStarving.value,
          '\n',
          'isUserStatsStarving:',
          isUserStatsStarving.value,
          '\n',
          'getters.unresolvedLongInvocationCount:',
          getters.unresolvedLongInvocationCount,
          '\n',
          'isVisible:',
          isVisible.value,
          '\n',
          'isEnsureConnectionProcessing:',
          isEnsureConnectionProcessing,
          '\n'

          // "isProcessingVisibilityChange:",
          // isProcessingVisibilityChange.value
        );

        if (value) {
          if (!timerId) {
            timerId = setTimeout(async () => {
              await showAbnormalConnection();
            }, 5000);
          }
        } else {
          clearTimeout(timerId);
          timerId = null;
          await hideAbnormalConnection(
            !isEnsureConnectionProcessing && isVisible.value
          );
        }
      }
    );
  },

  async connAppDisconnected({ commit }) {
    commit('positions/UPDATE_LOADING_SLEEP_STATUS', true, { root: true });
    commit('session/SET_LOADING_AFTER_SLEEP', true, { root: true });

    commit('setConnAppIsConnected', false);
  },
  async connAppConnected({ commit }) {
    commit('setConnAppIsConnected', true);
  },
  async connMarketsDisconnected({ commit }) {
    commit('markets/SET_LOADING_AFTER_SLEEP', true, { root: true });
    commit('setConnMarketsIsConnected', false);
  },
  async connMarketsConnected({ commit }) {
    commit('setConnMarketsIsConnected', true);
  },
  async subscribeToNetworkEvents({ commit, dispatch }) {
    // connApp.subscribeToClientEvent(emitEvents.invocationError, () => {
    //   notify({
    //     text: 'Api Invocation Error...',
    //     type: 'info',
    //   });
    // });

    connApp.subscribeToClientEvent(emitEvents.reconnecting, async () => {
      await dispatch('connAppDisconnected');
    });

    connMarkets.subscribeToClientEvent(emitEvents.reconnecting, async () => {
      await dispatch('connMarketsDisconnected');
    });

    connApp.subscribeToClientEvent(
      emitEvents.cannotEstablishConnection,
      async () => {
        await dispatch('connAppDisconnected');
      }
    );

    connMarkets.subscribeToClientEvent(
      emitEvents.cannotEstablishConnection,
      async () => {
        await dispatch('connMarketsDisconnected');
      }
    );

    connApp.subscribeToClientEvent(
      emitEvents.connectionNotEstablished,
      async () => {
        await dispatch('connAppDisconnected');
      }
    );

    connMarkets.subscribeToClientEvent(
      emitEvents.connectionNotEstablished,
      async () => {
        await dispatch('connMarketsDisconnected');
      }
    );

    connApp.subscribeToClientEvent(
      emitEvents.connectionEstablished,
      async () => {
        await dispatch('connAppConnected');
      }
    );

    connMarkets.subscribeToClientEvent(
      emitEvents.connectionEstablished,
      async () => {
        await dispatch('connMarketsConnected');
      }
    );

    connApp.subscribeToClientEvent(emitEvents.invocationIsTooLong, () =>
      commit('incrementUnresolvedLongInvocationCount')
    );

    connMarkets.subscribeToClientEvent(emitEvents.invocationIsTooLong, () =>
      commit('incrementUnresolvedLongInvocationCount')
    );

    connApp.subscribeToClientEvent(emitEvents.invocationResolved, () =>
      commit('decrementUnresolvedLongInvocationCount')
    );

    connMarkets.subscribeToClientEvent(emitEvents.invocationResolved, () =>
      commit('decrementUnresolvedLongInvocationCount')
    );

    connApp.subscribeToClientEvent(emitEvents.invocationRejected, () =>
      commit('decrementUnresolvedLongInvocationCount')
    );

    connMarkets.subscribeToClientEvent(emitEvents.invocationRejected, () =>
      commit('decrementUnresolvedLongInvocationCount')
    );
  },
};
export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
