import { connApp } from '@/helpers/signalR';
import { PositionTypes } from '@/modules/positions/helpers';

import { useStore } from 'vuex';

export default function useLoadPositions() {
  const store = useStore();

  const loadAllPositions = async () => {
    connApp
      .invokeWithRetry('FilterUserPositions', {
        state: 'Open,Proposed',
        take: 200,
      })
      .then((positions) => {
        store.dispatch('positions/setAllPositions', positions);

        // load history later
        setTimeout(() => {
          connApp
            .invokeWithRetry('FilterUserPositions', {
              state: 'History',
              take: 200,
            })
            .then(async (positions) => {
              const openPositions =
                store.getters['positions/getPositionsByType'][
                  PositionTypes.open
                ];
              const pendingPositions =
                store.getters['positions/getPositionsByType'][
                  PositionTypes.pending
                ];

              store.dispatch('positions/setAllPositions', [
                ...openPositions,
                ...pendingPositions,
                ...positions,
              ]);

              const historyStats = await connApp.invokeWithRetry(
                'GetClosedPositionsStats'
              );

              store.commit('session/SET_USER_TRADE_INFO_HISTORY', historyStats);
            });
        }, 1000);
      });
  };

  return { loadAllPositions };
}
