<template>
  <div class="positions-items">
    <ModalMarketOpenClose
      v-if="showMarketOpenClose"
      :hide-again-btn="true"
      :value="workHour"
      @close="
        showMarketOpenClose = false;
        // eslint-disable-next-line prettier/prettier
        workHour = '';
      "
    />
    <TableHeader
      :no-sort="true"
      is-positions
      :columns="tableData.positionsColumns.filter((c) => c.title)"
      :grid="tableData.positionsTableGrid.tableHeader"
      class="positions-table__header"
      :style="{
        'padding-left': '2.3rem',
        'padding-right': '3.2rem',
      }"
    />
    <div :class="{ demo: !$isLoggedIn }" class="positions-table__body">
      <!-- <div v-if="loading && !sleepLoading" class="positions-table-empty">
        <loader />
      </div> -->
      <!-- <SkeletonMarkets
        v-if="loading && !sleepLoading && !positions.length"
      />-->
      <SkeletonMarkets
        v-if="
          (filterType !== 'history' &&
            loading &&
            !sleepLoading &&
            !positions.length &&
            $isLoggedIn) ||
          (historyLoading &&
            filterType === 'history' &&
            !positions.length &&
            $isLoggedIn)
        "
      />
      <div
        v-if="
          (filterType !== 'history' &&
            (!loading || sleepLoading) &&
            !positions?.length) ||
          (filterType === 'history' &&
            (!loading || sleepLoading) &&
            ($isLoggedIn ? !historyLoading : true) &&
            !positions?.length)
        "
        :class="{ standalone: wd.navigator.standalone }"
        class="positions-table-empty"
      >
        <div
          v-if="
            $isFiorin &&
            !disableBountyProgram &&
            (!newBountyTasks.length ||
              (newBountyTasks.length && +bountyBalanceUsd))
          "
          class="positions-table-empty__desc"
        >
          No trades to display.<br />
          Make your move and track it here!
        </div>
        <TabButton
          v-if="
            $isFiorin &&
            !disableBountyProgram &&
            (!newBountyTasks.length ||
              (newBountyTasks.length && +bountyBalanceUsd))
          "
          reverse-color
          title="OPEN RANDOM TRADE"
          class="open-trade-btn"
          @click="openTrade"
        />
        <template v-else>
          <div class="positions-empty-label text-secondary">
            {{ $t('tables.emptyList') }}
          </div>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://faq.dxs.app/"
            class="positions-empty-link text-secondary"
            >{{ $t('popups.contestModal.readFaq') }}</a
          >
        </template>
      </div>
      <template
        v-if="
          (filterType !== 'history' && positions?.length) ||
          (filterType === 'history' && (positions?.length || !historyLoading))
        "
      >
        <div
          v-for="(group, ndx) in positions"
          :key="group.key"
          class="positions-table__toggle"
        >
          <!-- ROW ITEM HEAD WRAP -->
          <div class="positions-table__toggle-head">
            <PositionsListItemWrapper
              :dataset="tableData.positionsColumns"
              :item="group"
              :is-demo="!$isLoggedIn"
              :user-trade-info="userTradeInfo"
              :user-trade-info-history="userTradeInfoHistory"
              :total-group-amount="getTotalGroupAmount(group)"
              :filter-type="filterType"
              :opened="group.data.opened"
              :grid="tableData.positionsTableGrid.groupHeader"
              :is-mobile="isMobile"
              :key="group.key"
              @click="group.data.opened = !group.data.opened"
            />
          </div>
          <!-- ROW INFO -->
          <template v-if="group.data.opened">
            <PositionsListItem
              :show-last-position="ndx === 0 && showLastPosition"
              :sleep-loading="sleepLoading"
              :dataset="tableData.positionsColumns"
              :positions="group"
              :filter-type="filterType"
              :grid="tableData.positionsTableGrid.position"
              :is-mobile="isMobile"
              :key="group.key"
              @closePosition="onClosePosition"
            />
          </template>
        </div>
      </template>
    </div>
    <p
      v-if="filterType === PositionTypes.history && positionsCount >= 200"
      class="positions-items--export"
      :class="{ theme }"
    >
      <i18n-t keypath="order.history.exportText">
        <template v-slot:number>
          <span>{{ positionsCount }}</span>
        </template>
        <template v-slot:exportHistory>
          <a
            class="positions-items--link decoration--underlined underline"
            @click="exportHistoryHandler"
          >
            {{ $t('order.history.exportHistory') }}
          </a>
        </template>
      </i18n-t>
    </p>
  </div>
</template>
<script>
import TableHeader from '@/components/ui/TableHeader';
import PositionsListItemWrapper from './PositionsListItemWrapper';
import PositionsListItem from './PositionsListItem';

import { computed, watch, ref, onBeforeMount, onMounted } from 'vue';
import { useStore } from 'vuex';
import { PositionTypes } from '../helpers';
import useUserActions from '@/compositions/useUserActions';
import { usePositionsTable } from '@/compositions/usePositionsTable';
import { usePositionsScroll } from '@/compositions/usePositionsScroll';
import SkeletonMarkets from '@/modules/markets/components/SkeletonMarkets';
import { connApp, getErrorDetails } from '@/helpers/signalR';

import useMarketCoin from '@/compositions/useMarketCoin';
import ModalMarketOpenClose from '@/components/modals/ModalMarketOpenClose';
import { workHours } from '@/helpers/utils';
import TabButton from '@/components/ui/TabButton';
import notify from '@/plugins/notify';
import { updateAndSetUserSettings } from '@/modules/settings/api';
import { setRouteFromActiveMarket } from '@/helpers/activeMarketRoute';
import { useRouter } from 'vue-router';
import authMixin from '@/mixins/auth.mixin';
import useAuth from '@/compositions/useAuth';

export default {
  name: 'PositionsList',
  mixins: [authMixin],
  components: {
    TabButton,
    TableHeader,
    PositionsListItemWrapper,
    PositionsListItem,
    SkeletonMarkets,
    ModalMarketOpenClose,
  },
  emits: ['closePosition'],
  props: {
    hideAllPositions: {
      type: Boolean,
      default: false,
    },
    filterType: {
      type: String,
      required: true,
    },
    positions: {
      type: Array,
      required: true,
    },
    isMobile: {
      type: Boolean,
      required: false,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    sleepLoading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const auth = useAuth();
    const store = useStore();
    const router = useRouter();
    const { loadMorePositions } = usePositionsScroll();
    const userComposition = useUserActions();
    const historyLoading = ref(false);
    const firstLoading = ref(false);
    const marketsGroupAmounts = ref({});
    const showLastPosition = ref(false);
    const { isMarketActive } = useMarketCoin();

    const showMarketOpenClose = ref(false);
    const workHour = ref('');

    const userTradeInfo = computed(
      () => store.getters['session/userTradeInfo']
    );

    const userTradeInfoHistory = computed(
      () => store.getters['session/userTradeInfoHistory']
    );

    const uiSettings = computed(() => {
      return store.getters['settings/uiSettings'];
    });

    const disableBountyProgram = computed(
      () => store.getters['settings/settings']?.disableBountyProgram
    );

    // const marketsList = computed(() => store.getters['markets/marketsByName']);
    // const flagHalfClose = computed(
    //   () => store.getters['positions/flagHalfClose']
    // );

    const tableData = computed(() => {
      const { positionsColumns, positionsTableGrid } = usePositionsTable(
        props.isMobile,
        props.filterType
      );

      return {
        positionsColumns: positionsColumns.value,
        positionsTableGrid: positionsTableGrid.value,
      };
    });

    const getTotalGroupAmount = (group) => {
      const buy = marketsGroupAmounts.value[group.data.market]?.buyAmount || 0;
      const sell =
        marketsGroupAmounts.value[group.data.market]?.sellAmount || 0;

      return group.key.includes('buy') ? buy : sell;
    };

    const theme = computed(() => {
      return store.getters['settings/uiSettings']['theme'];
    });

    const checkHistory = () => {
      if (props.filterType === 'history') {
        if (!props.positions.length && !firstLoading.value) {
          historyLoading.value = true;
          firstLoading.value = true;
          setTimeout(() => {
            historyLoading.value = false;
          }, 7000);
        }
      }
    };

    // const getFilterType = () => {
    //   if (props.filterType === 'open') {
    //     return 1;
    //   }
    //   if (props.filterType === 'pending') {
    //     return 2;
    //   }
    //   if (props.filterType === 'history') {
    //     return 4;
    //   }
    // };

    const positionsCount = computed(() => {
      return props.positions.reduce((prevValue, currentValue) => {
        return prevValue + currentValue.list?.length || 0;
      }, 0);
    });

    // const calcTotalAmount = async () => {
    //   let error;

    //   props.positions.forEach((group) => {
    //     const groupMarketId = marketsList.value[group.data.market]?.id;
    //     if (!marketsGroupAmounts.value[group.data.market]) {
    //       marketsGroupAmounts.value[group.data.market] = {
    //         amount: 0,
    //         id: groupMarketId,
    //         market: group.data.market,
    //       };
    //     }
    //   });

    //   const res = await connApp
    //     .invoke(
    //       'GetMarketsTotalAmount',
    //       Object.keys(marketsGroupAmounts.value).map(
    //         (name) => marketsGroupAmounts.value[name].id
    //       ),
    //       getFilterType()
    //     )
    //     .catch((err) => {
    //       error = getErrorDetails(err);
    //     });

    //   if (!error) {
    //     res.forEach((item) => {
    //       Object.keys(marketsGroupAmounts.value).forEach((name) => {
    //         if (marketsGroupAmounts.value[name].id == item.marketId) {
    //           marketsGroupAmounts.value[name] = {
    //             ...marketsGroupAmounts.value[name],
    //             ...item,
    //           };
    //         }
    //       });
    //     });
    //   }
    // };

    checkHistory();

    const checkGroup = () => {
      if (['open', 'pending'].includes(props.positions[0]?.data.type)) {
        const hasOneGroup = props.positions.some((group) => {
          return group.list.length > 1;
        });
        if (hasOneGroup) {
          store.dispatch('localUiSettings/togglePositionGroup', true);
        } else {
          store.dispatch('localUiSettings/togglePositionGroup', false);
        }
      }
    };

    watch(
      () => auth.isLoggedIn,
      () => {
        if (auth.isLoggedIn.value) {
          window.addEventListener('scroll', onScroll);
        }
      }
    );

    onMounted(() => {
      if (props.filterType === 'history') {
        store.dispatch('localUiSettings/togglePositionGroup', true);
      } else {
        checkGroup();
      }
      if (auth.isLoggedIn.value) {
        window.addEventListener('scroll', onScroll);
      }
    });

    watch(
      () => props.filterType,
      () => {
        if (props.filterType === 'history') {
          store.dispatch('localUiSettings/togglePositionGroup', true);
        } else {
          checkGroup();
        }
        checkHistory();
      }
    );

    watch(
      () => props.hideAllPositions,
      () => {
        props.positions.forEach((group) => {
          group.data.opened = !props.hideAllPositions;
        });
      }
    );

    watch(
      () => positionsCount.value,
      () => {
        checkGroup();
      }
    );

    // watch(
    //   () => flagHalfClose.value,
    //   async () => {
    //     if (flagHalfClose.value) {
    //       setTimeout(async () => {
    //         await calcTotalAmount();

    //         store.dispatch('positions/toggleFlagHalfClose', false);
    //       }, 3000);
    //     }
    //   }
    // );

    // watch(
    //   () => positionsCount.value,
    //   async () => {
    //     // if (newV - oldV === 1) {
    //     //   setTimeout(() => {
    //     //     // render group (lag) and after animate..
    //     //     showLastPosition.value = true;

    //     //     setTimeout(() => {
    //     //       showLastPosition.value = false;
    //     //     }, 1000);
    //     //   }, 700);
    //     // }
    //     if (positionsCount.value && props.positions.length) {
    //       await calcTotalAmount();
    //     }
    //   }
    // );

    const bountyBalanceUsd = computed(() => store.getters['bounty/balanceUsd']);

    const onClosePosition = (position) => {
      if (position.state === 'proposed') {
        emit('closePosition', position);

        return;
      }
      const market = store.getters['markets/marketsById'][position.market_id];

      if (market && position.state === 'opened' && !isMarketActive(market)) {
        workHour.value = workHours(new Date(), market);
        showMarketOpenClose.value = true;
        return;
      }

      if (position.is_bounty) {
        const phoneConfirmed =
          store.getters['settings/settings']?.phoneNumberConfirmed;

        if (!phoneConfirmed) {
          return store.dispatch('burger/phoneVerification', { auto: false });
        }

        return emit('closePosition', position);
      }

      if (!userComposition.isEmailVerified()) {
        // return userComposition.notifyEmailRequired();
      }

      emit('closePosition', position);
    };

    const scrollLoading = ref(false);

    const onScroll = async () => {
      const element = document.querySelector('.positions-list');

      if (!element) {
        return;
      }

      if (props.filterType !== 'open' || !auth.isLoggedIn.value) {
        return;
      }

      const scrollTop = document.documentElement.scrollTop;
      const contentHeight =
        document.documentElement.clientWidth >= 480
          ? document.body.clientHeight
          : document.querySelector('.positions-list')?.clientHeight || 0;

      if (
        contentHeight - scrollTop - 10 <=
          document.documentElement.clientHeight &&
        !scrollLoading.value
      ) {
        scrollLoading.value = true;
        await loadMorePositions();
        scrollLoading.value = false;
      }
    };

    const startLoading = ref(false);
    const newBountyTasks = computed(
      () => store.getters['bounty/newBountyTasks']
    );

    const activeMarket = computed(() => store.getters['markets/activeMarket']);
    const marketsByName = computed(
      () => store.getters['markets/marketsByName']
    );

    const setMarket = async () => {
      store.commit(
        'markets/UPDATE_ACTIVE_MARKET',
        marketsByName.value['BTC/USD']
      );
      setRouteFromActiveMarket({ stopDefault: true });

      if (activeMarket.value?.id) {
        await store.dispatch(
          'volumeLimits/fetchLoyaltyFeeInfo',
          activeMarket.value.id
        );
      }
    };

    const openTrade = async () => {
      console.log('must start', newBountyTasks.value.length);
      if (!bountyBalanceUsd.value && !newBountyTasks.value.length) {
        if (startLoading.value) {
          return;
        }

        startLoading.value = true;
        await connApp
          .invokeImmediately('StartBountyProgram')
          .then(async () => {
            await Promise.all([
              store.dispatch('bounty/updateNewBountyBalance'),
              store.dispatch('bounty/updateActivityPointsBalance'),
              updateAndSetUserSettings({
                uiSettings: {},
              }),
            ]);

            startLoading.value = false;
            setMarket();
            await router.push('/positions');
            store.dispatch('randomTrade/setOpenRandomPositionFlag', true);
          })
          .catch((err) => {
            notify({ text: getErrorDetails(err).message, type: 'info' });
            startLoading.value = false;
          });
      } else {
        setMarket();
        await router.push('/positions');
        store.dispatch('randomTrade/setOpenRandomPositionFlag', true);
      }
    };

    onBeforeMount(() => {
      window.removeEventListener('scroll', onScroll);
    });

    return {
      wd: window,
      uiSettings,
      disableBountyProgram,
      showMarketOpenClose,
      workHour,
      tableData,
      // positionsColumns,
      // positionsTableGrid,
      onClosePosition,
      positionsCount,
      exportHistoryHandler: userComposition.exportHistory,
      bountyBalanceUsd,
      PositionTypes,
      theme,
      historyLoading,
      firstLoading,
      marketsGroupAmounts,
      getTotalGroupAmount,
      showLastPosition,
      userTradeInfo,
      userTradeInfoHistory,
      newBountyTasks,
      openTrade,
    };
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/styles/colors';
@import '@/assets/styles/base';

@media screen and (max-width: 1024px) {
  .positions-table__body {
    &.demo {
      padding-bottom: 85px;
    }
  }
}

.positions-items {
  &--export {
    padding: 2rem 3.25rem;
    color: rgba($color-gray, 0.9);
    text-align: center;
    font-size: 0.9375rem;
    line-height: 18px;
    margin: 0;
    background: $color-white;
    font-family: Gotham_Pro_Regular;
    text-align: center;

    a {
      text-decoration-thickness: 1px;
    }

    span {
      color: rgba($color-gray, 0.9);
    }
  }

  &--link {
    cursor: pointer;
    color: rgba($color-gray, 0.9);
    text-decoration: underline;
  }
}

// @media screen and (max-width: 480px) {
//   .positions-items {
//     height: calc(100vh - 185px);
//     overflow-y: auto;
//   }
// }

// @media screen and (max-width: 418px) {
//   .positions-items {
//     height: calc(100vh - 220px);
//     overflow-y: auto;
//   }
// }

.positions-table__toggle-head {
  // margin-bottom: 1px;
}

.positions-table-empty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 200px;

  .open-trade-btn {
    min-width: 264px;
    height: 60px;
    font-size: 18px;
    color: rgba(0, 117, 186, 1);
    font-family: Gotham_Pro_Medium;
  }

  &__desc {
    display: flex;
    font-size: 15px;
    color: rgba(255, 255, 255, 0.6);
    font-family: Gotham_Pro_Regular;
    line-height: 20px;
    text-align: center;
    margin-bottom: 20px;
  }

  @media (max-width: 1024px) {
    height: calc(100vh - 430px);

    &.standalone {
      height: calc(100vh - 280px);
    }
  }

  .positions-empty-label {
    font-size: 0.9375rem;
    font-family: Gotham_Pro_Regular;
    text-transform: uppercase;
  }

  .positions-empty-link {
    font-size: 0.9375rem;
    font-family: Gotham_Pro_Regular;
    text-transform: uppercase;
    margin-top: 15px;
  }
}

body.light {
  .positions-table-empty {
    &__desc {
      color: #888;
    }
  }
}
</style>
