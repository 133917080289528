<template>
  <div class="notification" :class="`notification--${type}`">
    <div class="notification__content">
      <div v-html="text" class="notification__text" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Notification',
  components: {},
  emits: ['close'],
  props: {
    type: {
      type: String,
      default: 'info',
    },
    title: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
.notification {
  position: relative;
  display: flex;
  cursor: pointer;
  margin-bottom: 10px;
  background-color: #fff;
  color: #fff;
  font-family: Gotham_Pro_Medium;
  box-shadow: 0 15px 50px rgba(80, 100, 124, 0.1),
    0 10px 15px rgba(80, 100, 124, 0.16);
  overflow: hidden;

  width: fit-content;
  max-width: 100vw;

  transition: all ease 0.4s;

  &--warning {
    background-color: rgb(122, 46, 44);
  }

  &--error {
    background-color: rgb(122, 46, 44);
  }

  &--info {
    background-color: rgb(44, 66, 132);
  }

  &__content {
    padding: 15px;
  }

  &__title {
    font-family: 'Panton_Bold';
    font-size: 20px;
    line-height: 30px;
  }

  &__text {
    font-size: 14px;
    line-height: 20px;
  }
}
</style>
