<template>
  <Modal stop-mobile-max-height to-top @close="onClose">
    <Card
      :title="$t('tradingSession.titles.profitRecovery').toUpperCase()"
      class="card"
    >
      <div class="recovery-modal">
        <div class="nomargin">
          {{ $t('tradingSession.titles.info11') }}
        </div>
        <div>
          {{ $t('tradingSession.titles.info12') }}
        </div>
        <div>
          {{ $t('tradingSession.titles.info13') }}
        </div>
        <ItemLabelValue
          :label="$t('tradingSession.titles.yourProfit')"
          value=""
        >
          <span v-html="userDeductedProfit" />
        </ItemLabelValue>
      </div>
    </Card>
    <div
      :class="{
        standalone:
          wd.navigator.standalone || isRunningStandaloneAndroid() || isTelegram,
      }"
      class="btns"
    >
      <Button
        :text="$t('foundation.learnMore')"
        type="secondary"
        @click="redirectToFaq"
      />
      <!-- <Button
        :text="$t('tradingSession.titles.shutDown')"
        type="secondary"
        @click="onClose"
      /> -->
    </div>
  </Modal>
</template>

<script>
import Modal from '@/modules/modals/Modal';
import Card from '@/components/ui/Card';
import Button from '@/components/ui/Button';
import ItemLabelValue from '@/components/ui/ItemLabelValue';
import { computed, onMounted, ref } from 'vue';
import { connApp } from '@/helpers/signalR';
import notify from '@/plugins/notify';
import { usdBsvFormatAmount } from '@/helpers/utils';
import { useI18n } from 'vue-i18n';
import { isRunningStandaloneAndroid } from '@/helpers/detects';
import { useStore } from 'vuex';

const EVENTS = {
  close: 'close',
};

export default {
  components: { Modal, Card, Button, ItemLabelValue },
  emits: [EVENTS.close],
  setup(_, { emit }) {
    const { t } = useI18n();
    const userProfit = ref(0);
    const store = useStore();

    onMounted(() => {
      connApp
        .invokeWithRetry('GetUserTotalRebate')
        .then((res) => {
          userProfit.value = res;
        })
        .catch(() => {
          notify({ text: t('order.history.waitAndTryAgain'), type: 'info' });
          userProfit.value = 0;
        });
    });

    const userDeductedProfit = computed(() => {
      return usdBsvFormatAmount(userProfit.value);
    });

    const redirectToFaq = () => {
      window.open('https://faq.dxs.app/trading/profit-deduction', '_blank');
    };

    const onClose = () => {
      emit(EVENTS.close);
    };

    const isTelegram = computed(() =>
      Boolean(store.getters['telegram/instance'])
    );

    return {
      userDeductedProfit,
      onClose,
      redirectToFaq,
      wd: window,
      isTelegram,
      isRunningStandaloneAndroid,
    };
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/styles/colors';
@import '@/assets/styles/base';

.recovery-modal {
  height: auto;
  max-height: calc(100% - 102px);
  width: calc(100% + 40px);
  margin-left: -20px;
  overflow-y: auto;
  margin-bottom: 45px;
  padding: 0 20px;
  box-sizing: border-box;
  font-family: Gotham_Pro_Regular;
  line-height: 20px;

  @media screen and (min-width: 1024px) {
    max-height: calc(100vh - 200px) !important;
  }

  div {
    margin-top: 25px;

    &.nomargin {
      margin: 0;
    }

    h4 {
      font-family: Gotham_Pro_Bold !important;
    }
  }

  &__issue {
    display: flex;
    align-items: center;
    margin-top: 10px;

    &.last {
      margin-bottom: 20px;
    }

    .check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;
      border-radius: 4px;
      margin-top: 0;
      border: 1px solid #ccc;
    }

    .label-description {
      margin-top: 1px;
      margin-left: 10px;
    }
  }

  p {
    &.underline {
      text-decoration-line: underline;
    }
  }
}

.btns {
  width: 100%;
  margin-top: 30px;
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 1024px) {
    bottom: 50px;

    &.standalone {
      bottom: 82px;
    }
  }

  a {
    text-decoration: none;
  }
}
</style>
