// import Radio from '@/components/app/Radio';
import { connApp } from '@/helpers/signalR';
import notify from '@/plugins/notify';
import { listenPositions, SetType } from '../positionListener';
import { PositionTypes } from '../helpers';

export default function (store) {
  connApp.subscribeToServerEvent('OnNotification', (data) => {
    console.log('BOUNTY POINTS NOTIFY', data);

    notify({
      text: data.text,
      type: 'info',
    });
  });

  const updateBalance = async (position) => {
    const isLoggedIn = store.getters['auth/isLoggedIn'];
    if (isLoggedIn) {
      await store.dispatch('wallet/fetchBalance');

      await store.dispatch('bounty/updateBalance');
      await store.dispatch(
        'volumeLimits/fetchLoyaltyFeeInfo',
        position.market_id
      );
      await store.dispatch(
        'volumeLimits/fetchLoyaltyFeeRate',
        position.market_id
      );
    }
  };

  // todo: [Vadim] is it right place for this?
  connApp.subscribeToServerEvent('OnBountyReceived', () => {
    const isFiorin = store.getters['auth/isFiorin'];
    if (isFiorin) {
      // notify({
      //   text: 'Bounty received',
      //   type: 'info',
      //   group: 'bounty',
      // });

      store.dispatch('bounty/updateBalance');
      store.dispatch('burger/bountyTrading');
    }
  });

  store.listenMutation('markets/UPDATE_MARKETS', () => {
    if (window.document.body.clientWidth >= 480) {
      store.dispatch('positions/setPositionsStructure');
      return;
    }

    if (window.location.pathname.includes('positions')) {
      store.dispatch('positions/setPositionsStructure');
    }
  });

  const notifyPositionUpdated = (position, state, store) => {
    if (store.getters['localUiSettings/removeAllPositionsAStatus']) {
      return;
    }
    notify({
      text: `${position.market} ${position.side} ${state}`,
      type: 'info',
      group: state,
    });
  };

  const getPositionById = (id) =>
    store.getters['positions/getPositionsById'][id];

  connApp.subscribeToServerEvent(
    'OnPositionChanged',
    listenPositions(({ set, type }) => {
      console.log('OnPositionChanged', set, type);
      if (
        set[0] &&
        set.some((item) => ['proposed', 'updated'].includes(item.state))
      ) {
        connApp
          .invokeWithRetry('FilterUserPositions', {
            state: 'Open,Proposed',
            take: 200,
          })
          .then((positions) => {
            store.dispatch('positions/setAllPositions', positions);

            // load history later
            setTimeout(() => {
              connApp
                .invokeWithRetry('FilterUserPositions', {
                  state: 'History',
                  take: 200,
                })
                .then(async (positions) => {
                  const openPositions =
                    store.getters['positions/getPositionsByType'][
                      PositionTypes.open
                    ];
                  const pendingPositions =
                    store.getters['positions/getPositionsByType'][
                      PositionTypes.pending
                    ];

                  store.dispatch('positions/setAllPositions', [
                    ...openPositions,
                    ...pendingPositions,
                    ...positions,
                  ]);

                  const historyStats = await connApp.invokeWithRetry(
                    'GetClosedPositionsStats'
                  );

                  store.commit(
                    'session/SET_USER_TRADE_INFO_HISTORY',
                    historyStats
                  );
                });
            }, 1000);
          });
      }
      // Split
      if (type === SetType.linked) {
        const [first] = set;

        notifyPositionUpdated(first.position, 'split', store);
      }

      // Usual position updates
      if (type === SetType.independent) {
        set.forEach(async ({ position, state }) => {
          if (state === PositionTypes.duplicate) {
            return;
          }

          // TODO: remove this dirty-hack after backend fixed it
          if (state === PositionTypes.updated) {
            const oldPosition = getPositionById(position.id);

            if (
              oldPosition.opened !== position.opened ||
              oldPosition.proposed !== position.proposed
            ) {
              return;
            }
          }

          if (
            [PositionTypes.closed, PositionTypes.canceled].includes(
              position.state
            )
          ) {
            const historyStats = await connApp.invokeWithRetry(
              'GetClosedPositionsStats'
            );

            store.commit('session/SET_USER_TRADE_INFO_HISTORY', historyStats);
            store.dispatch('session/toggleGrandTotalLoader');
          }

          if (position.state !== PositionTypes.history) {
            if (set.length < 3) {
              // hide message when pack of remove all positions
              notifyPositionUpdated(position, state, store);
            }
          }

          setTimeout(async () => {
            if (set.length < 3) {
              await updateBalance(position);
            }
          }, 12000);
        });
      }

      set.forEach(({ position }) => {
        if (position.marginTx === null) {
          delete position.marginTx;
        }

        if (position.profitTx === null) {
          delete position.profitTx;
        }

        store.dispatch('positions/updatePosition', position);
      });

      store.dispatch('session/updateInfo');
      store.dispatch('bounty/updateBalance');

      const market = store.getters['markets/activeMarket'];
      if (market && market.id) {
        store.dispatch('volumeLimits/fetchGroupActiveAmount', market.id);
      }
    }, getPositionById)
  );

  connApp.subscribeToServerEvent('OnTransactionOut', (data) => {
    async function onNotifyOutputTransaction(result) {
      if (result.type === 'ReturnMargin') {
        // ProfitPay
        const position =
          store.getters['positions/getPositionsById'][result.positionId];
        if (position) {
          // const activeConnect = store.getters['connectors/activeConnect'];
          // const margin = calcIncomingMarginPosition(position);

          // todo: [Vadim] can I delete this or it's important?
          // if (activeConnect.provider !== userProviders.fiorin) {
          //   activeConnect.satoshiBalance += margin;
          //   store.dispatch('connectors/setActiveConnect', activeConnect);
          // } else {
          //   activeConnect.satoshiBalanceUsd += margin;
          //   store.dispatch('connectors/setActiveConnect', activeConnect);
          // }

          // store.dispatch('positions/updateIncomingBalance', {
          //   positions: -margin,
          // });

          setTimeout(async () => {
            await updateBalance(position);
          }, 5000);
          if (position.marginTx) {
            position.marginTx.txId = result.txId;
          } else {
            position.marginTx = { txId: result.txId };
          }
        }
      }
    }

    try {
      onNotifyOutputTransaction(data);
    } catch (e) {
      console.log(e);
    }
  });
}
