<template>
  <Modal :stop-mobile-max-height="true" to-top @close="$emit('close')">
    <Card
      title-class="capitalize"
      :title="$t('popups.orderConfirm.titleNew')"
      :description="orderCardDescrition"
      :replace-str-upper="marketTicker(activeMarket.name)"
    >
      <FormPositionConfirm
        :is-bounty="true"
        :active-market="activeMarket"
        :orderType="'buy'"
        :margin-value-custom="formData.marginBsv"
        :formData="formData"
        @payment="onPayment"
        @error="onError"
        @on-close-order-confirm="$emit('close')"
      />
    </Card>
  </Modal>
</template>
<script>
import Modal from '@/modules/modals/Modal';
import Card from '@/components/ui/Card';
import FormPositionConfirm from '@/modules/formPosition/components/FormPositionConfirm';
import { marketTicker } from '@/config/wrapMarkets';
import { useStore } from 'vuex';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

export default {
  components: {
    Modal,
    Card,
    FormPositionConfirm,
  },
  setup(_, { emit }) {
    const store = useStore();
    const { t } = useI18n();

    const activeMarket = computed(() => {
      return store.getters['markets/activeMarket'];
    });

    const orderCardDescrition = computed(() => {
      return t('popups.orderConfirm.titleDescriptionOpen', {
        name: marketTicker(activeMarket.value.name),
        type: 'buy',
      });
    });

    const formData = computed(() => store.getters['randomTrade/randomTrade']);

    const onPayment = () => {
      emit('close');
    };

    const onError = () => {
      emit('close');
    };

    return {
      orderCardDescrition,
      activeMarket,
      marketTicker,
      formData,
      onPayment,
      onError,
    };
  },
};
</script>
<style lang="scss" scoped></style>
