import store from '@/store';

export default async (params = {}) => {
  const defaultParams = {
    warning: {
      title: 'Error',
    },
    info: {
      title: 'Info',
    },
    success: {
      title: 'Success',
    },
  };

  const id = (+new Date()).toString(36);

  const getParams = (params = {}) => {
    const type = params.type || 'warning';
    const title = params.title || defaultParams[type]?.title;
    const text = params.text || defaultParams[type]?.text;
    const duration = params.duration || 3000;
    const group = params.group;

    return {
      id,
      type,
      title,
      text,
      duration,
      group,
    };
  };

  await store.dispatch('notifications/addNotification', getParams(params));

  return id;
};

export const removeNotification = async (id) => {
  await store.dispatch('notifications/removeNotification', id);
};
