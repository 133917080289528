import {
  HttpTransportType,
  HubConnectionBuilder,
  HubConnectionState,
  JsonHubProtocol,
  LogLevel,
} from '@microsoft/signalr';
import EventEmitter from 'events';
import config from '@/config';
import { v4 as uuidv4 } from 'uuid';
import { sleep, waitUntil } from '@/helpers/retry';
import { invokeConfigurable } from '@/helpers/signalR/invoke';
import { emitEvents } from '@/helpers/signalR/emitEvents';
import { OneDayMs, OneWeekMs } from '@/helpers/timeConstants';

const RECONNECT_TIMEOUT = 1000;

class SignalRConnection {
  constructor(host, withAuth) {
    this._eventEmitter = new EventEmitter();
    this.host = host;
    this.withAuth = withAuth;
    this._provider = null;
    this._accessToken = null;
    this._isRestarting = false;
    this._registeredEventHandlers = {};

    this._started = false;

    this._createConnection();
  }

  _createConnection = () => {
    const thisConnectionId = uuidv4();

    const connection = new HubConnectionBuilder()
      .withUrl(this._buildConnectionString(), {
        accessTokenFactory: this.withAuth
          ? this._accessTokenFactory
          : undefined,
        withCredentials: false,
        transport: HttpTransportType.WebSockets,
      })
      .withHubProtocol(new JsonHubProtocol())
      .withAutomaticReconnect({
        nextRetryDelayInMilliseconds: () => Math.random() * RECONNECT_TIMEOUT,
      })
      .withKeepAliveInterval(10_000)
      .withServerTimeout(20_000)
      .configureLogging(LogLevel.Debug)
      .build();

    const IfSameConnection = (eventName, fn) => {
      return async (...args) => {
        if (this.activeConnectionId === thisConnectionId) {
          await fn(...args);
        } else {
          // [Vadim] yes, it happens as I anticipated
          this._logDebug(
            'Not an error, but important. Connection is not the same, will not call the function.',
            '\n',
            'eventName:',
            eventName
          );
        }
      };
    };

    connection.onclose(
      IfSameConnection('onclose', async (error) => {
        this._logWarn(
          'Socket connection has closed #onclose',
          '\n',
          'error:',
          error || 'no error'
        );

        if (error) {
          // closed by timout or error
          this._emit(emitEvents.cannotEstablishConnection);
          await sleep(5_000);
          await this._restart('onclose', {});
        } else {
          //ok, closed by calling stop(), don't do restart here
        }
      })
    );

    connection.onreconnecting(
      IfSameConnection('onreconnecting', (error) => {
        this._logWarn(
          'Socket connection is reconnecting #onreconnecting',
          '\n',
          'error:',
          error || 'no error'
        );
        this._emit(emitEvents.reconnecting);
      })
    );

    connection.onreconnected(
      IfSameConnection('onreconnected', () => {
        this._logWarn('#onreconnected');

        this._applyEventHandlers();
        this._emit(emitEvents.connectionEstablished);
        this._emit(emitEvents.reconnected);
      })
    );

    this.activeConnectionId = thisConnectionId;
    this._connection = connection;
  };

  _recreateConnection = async (from, cancellationToken) => {
    this._logWarn('#recreateConnection.', '\n', 'from:', from);

    if (!cancellationToken) {
      this._logError('cancellationToken is not provided #recreateConnection');
      throw new Error(
        'cancellationToken is not provided to _recreateConnection'
      );
    }

    if (cancellationToken.isCancelled) {
      this._logDebug('cancellationToken is cancelled #recreateConnection');
      return;
    }

    const oldConnectionId = this.activeConnectionId;
    const oldConnection = this._connection;
    this._removeAllEventHandlersFromConnection(oldConnection);

    this._createConnection();
    this._applyEventHandlers();

    this._started = false;

    // try just in case, to avoid any unexpected behavior
    try {
      // do not await:
      oldConnection.stop().then(() => {
        this._logDebug(
          'Old connection was stopped #recreateConnection',
          '\n',
          'from:',
          from,
          '\n',
          'oldConnectionId:',
          oldConnectionId
        );
      });
    } catch (e) {
      this._logError(
        'Error when stopping old connection #recreateConnection',
        '\n',
        'from:',
        from,
        '\n',
        'oldConnectionId:',
        oldConnectionId,
        '\n',
        'error:',
        e
      );
    }

    await this.start('recreateConnection', cancellationToken);
    // these emits must be here
    this._emit(emitEvents.connectionEstablished);
    this._emit(emitEvents.reconnected);
  };

  _commonArgs = () => {
    return [
      '\n',
      '#SignalRConnection',
      '\n',
      'host:',
      this.host,
      '\n',
      'state:',
      this._connection.state,
      '\n',
      'activeConnectionId:',
      this.activeConnectionId,
    ];
  };

  _logDebug = (...args) => {
    console.debug(...args, ...this._commonArgs());
  };

  _logError = (...args) => {
    console.error(...args, ...this._commonArgs());
  };

  _logWarn = (...args) => {
    console.warn(...args, ...this._commonArgs());
  };

  _emit = (...args) => this._eventEmitter.emit(...args);

  // todo: [Vadim] check if this is needed
  // _ensureInValidState = async () => {
  //   if (document.visibilityState !== 'visible') {
  //     return;
  //   }
  //
  //   // disabled while debugging access tokens
  //   // // todo: [Vadim] check if this is needed
  //   // if (!this._hasAccessToken()) {
  //   //   await this._connection.stop();
  //   //   console.debug('Stopping connection because no access token #SignalRConnection #_ensureInValidState #_hasAccessToken');
  //   //   return;
  //   // }
  //
  //   if (
  //     this._connection.state === HubConnectionState.Disconnected ||
  //     this._connection.state === HubConnectionState.Disconnecting
  //   ) {
  //     this.connected = false;
  //
  //     await this.start();
  //   }
  // };

  _buildConnectionString = () => {
    let uuid = localStorage.getItem('dxsUUID');

    if (!uuid) {
      uuid = uuidv4();
      localStorage.setItem('dxsUUID', uuid);
    }

    return this.host + `?browserId=${uuid}`;
  };

  applyAccessData = async (provider, accessToken) => {
    this._provider = provider;
    this._accessToken = accessToken;

    await this.startOrRestart('restartWithNewAccessData');
  };

  _getAccessData = () => {
    if (!this.withAuth) {
      this._logError(
        'Why access data is requested when no auth is needed? #SignalRConnection #getAccessData'
      );

      return {
        provider: null,
        accessToken: null,
      };
    }
    if (!this._accessToken || !this._provider) {
      this._logDebug('No provider or accessToken provided #getAccessData');

      return {
        provider: null,
        accessToken: null,
      };
    }

    return {
      provider: this._provider,
      accessToken: this._accessToken,
    };
  };

  // _hasAccessToken = () => Boolean(this._getAccessData().accessToken);

  _accessTokenFactory = () => {
    if (!this.withAuth) {
      return;
    }

    const { provider, accessToken } = this._getAccessData();

    if (!accessToken) {
      return;
    }

    const token = `${provider}:${accessToken}`;
    return token;
  };

  _startInternal = async () => {
    try {
      const initialState = this._connection.state;
      if (!this.isConnectedOrConnectingOrReconnecting()) {
        let now = new Date();
        await this._connection.start();
        let elapsed = new Date() - now;

        this.unauthorized = false;
        this._logDebug(
          'Connection established (_startInternal) #start',
          '\n',
          'ms elapsed:',
          elapsed
        );

        this._emit(emitEvents.connectionEstablished);
      } else {
        this._logWarn(
          'Why calling start when connection is connected or connecting? #startInternal',
          '\n',
          'initialState:',
          initialState
        );
      }
    } catch (err) {
      if (err.statusCode === 401) {
        this._logError('Unauthorized #start', '\n', 'error:', err);
        // means accessToken is invalid, need to delete accessToken from store and relogin
        this.unauthorized = true;
        this._emit(emitEvents.unauthorized);

        return 'unauthorized';
      } else {
        this._logError(
          'Error when start connection #signalR #start, error:',
          err
        );
        throw err;
      }
    }
  };

  isConnectedOrConnectingOrReconnecting = () =>
    this._connection.state === HubConnectionState.Connected ||
    this._connection.state === HubConnectionState.Connecting ||
    this._connection.state === HubConnectionState.Reconnecting;

  startOrRestart = async () => {
    this._logDebug('#startOrRestart');
    if (this._started || this.isConnectedOrConnectingOrReconnecting()) {
      await this._restart('startOrRestart', {});
    } else {
      await this.start('startOrRestart');
    }
  };

  start = async (from, cancellationToken) => {
    this._logDebug('#start', '\n', 'from:', from);

    cancellationToken = cancellationToken || {};
    if (cancellationToken.isCancelled) {
      this._logDebug('cancellationToken is cancelled - 0 #start');
      return;
    }

    if (this._started) {
      this._logWarn(
        'Connection already started. Cannot star multiple times',
        '\n',
        'from:',
        from
      );
      return;
    }

    // [Vadim]  sometimes signalR throws an empty exception, without any details at all
    // do not delete this retry!

    let attemptCount = 1;

    const connected = await waitUntil(
      '#SignalRConnection #start',
      OneWeekMs,
      2000,
      async () => {
        // [Vadim] this logic is important, don't even dare to touch it without any significant reason! ;/

        if (cancellationToken.isCancelled) {
          this._logDebug('cancellationToken is cancelled - 1 #start');
          return true;
        }

        if (this._connection.state === HubConnectionState.Connected) {
          this._logDebug('Connection is already established #start');
          return true;
        }

        try {
          let error = await this._startInternal();
          // this._logDebug('after _startInternal #signalR #start r:', error);
          if (error === 'unauthorized') {
            this._logDebug('Connection was unauthorized #start');
            return true;
          }
        } catch (error) {
          if (this._connection.state === HubConnectionState.Connected) {
            this._logDebug(
              'Connection was established despite an error #start',
              '\n',
              'error:',
              error
            );
            return true;
          }

          this._emit(emitEvents.cannotEstablishConnection);

          if (cancellationToken.isCancelled) {
            this._logDebug('cancellationToken is cancelled - 2 #start');
            return true;
          }
          await sleep(Math.min(1000 * attemptCount++), 10_000);
          return false;
        }

        const lastState = this._connection.state;
        const connected = lastState === HubConnectionState.Connected;

        if (!connected) {
          this._logDebug(
            'Connection was not established, will try again #signalR #start',
            '\n',
            'lastState:',
            lastState
          );
          return false;
        } else {
          // no need in this log, since it's already logged in _startInternal
          // this._logDebug(
          //   'Connection was established #signalR #start',
          //   'host:',
          //   this.host
          // );
          return true;
        }
      }
    );

    if (cancellationToken.isCancelled) {
      this._logDebug('cancellationToken is cancelled - before end #start');
      return;
    }

    if (!connected) {
      // alert('Socket issues, please reload the page');
      this._emit(emitEvents.cannotEstablishConnection);
      throw new Error('Connection was not established');
    } else {
      for (let eventName in this._registeredEventHandlers) {
        this._connection.on(
          eventName,
          this._registeredEventHandlers[eventName]
        );
      }

      this._emit(emitEvents.connectionEstablished);
    }
  };

  stop = async (from) => {
    this._logError('#stop !!!', '\n', 'from:', from);
    throw new Error('Method not allowed to use.');

    // let intervalId;
    //
    // if (
    //   this._connection.state !== HubConnectionState.Disconnected &&
    //   this._connection.state !== HubConnectionState.Disconnecting
    // ) {
    //   try {
    //     const startDate = new Date();
    //
    //     intervalId = setInterval(() => {
    //       this._logWarn(
    //         'Connection is still stopping #stop ',
    //         '\n',
    //         'ms elapsed:',
    //         new Date() - startDate,
    //         '\n',
    //         'from:',
    //         from
    //       );
    //     }, 10_000);
    //
    //     const promise = this._connection.stop().then(() => {});
    //     await promise;
    //     clearInterval(intervalId);
    //   } catch (e) {
    //     clearInterval(intervalId);
    //     // error may happen when connection is not in 'Connected' state
    //     // even if we check it before, it still can happen
    //     // it's ok, just swallow it
    //     this._logWarn(
    //       'Error when stopping connection (its ok) #stop',
    //       '\n',
    //       'from:',
    //       from,
    //       '\n',
    //       'error:',
    //       e
    //     );
    //   }
    // } else {
    //   this._logDebug(
    //     'When called stop, connection is already Disconnected or Disconnecting #stop',
    //     '\n',
    //     'from:',
    //     from
    //   );
    // }
    //
    // if (this._connection.state !== HubConnectionState.Disconnected) {
    //   this._logWarn('Connection was not stopped #stop');
    // }
  };

  _restart = async (from, cancellationToken) => {
    this._logDebug(
      '#restart',
      'isAlreadyRestarting:',
      this._isRestarting,
      '\n',
      'from:',
      from
    );

    if (!cancellationToken) {
      this._logError('cancellationToken is not provided #restart');
      throw new Error('cancellationToken is not provided to _restart');
    }

    if (cancellationToken.isCancelled) {
      this._logDebug('cancellationToken is cancelled - 1 #restart');
      return;
    }

    if (this._isRestarting) {
      this._logWarn(
        'Already restarting, will wait 2sec and go on #restart',
        '\n',
        'from:',
        from
      );

      await waitUntil(
        '#SignalRConnection #restart ' + this.host,
        2_000,
        100,
        () => cancellationToken.isCancelled || !this._isRestarting
      );
    }

    if (cancellationToken.isCancelled) {
      this._logDebug('cancellationToken is cancelled - 2 #restart');
      return;
    }

    // should not use any AsyncLock or any, since there is possibility to get a hanging promise of signalR
    // especially in case of device sleep

    this._isRestarting = true;
    this._emit(emitEvents.restarting);
    try {
      await this._recreateConnection(from, cancellationToken);
    } finally {
      this._isRestarting = false;
    }
  };

  subscribeToServerEvent = (eventName, callback) => {
    if (!eventName) {
      this._logError('eventName is empty #subscribeToServerEvent');
      throw new Error('eventName is empty');
    }

    if (typeof callback !== 'function') {
      this._logError('callback is not a function #subscribeToServerEvent');
      throw new Error('callback is not a function');
    }

    // console.debug(
    //   `Subscribing to server event: ${eventName} #subscribe #SignalRConnection`
    // );

    const oldHandler = this._registeredEventHandlers[eventName];
    if (oldHandler) {
      this._connection.off(eventName, oldHandler);
    }

    this._registeredEventHandlers[eventName] = callback;

    this._connection.on(eventName, async (...args) => {
      const fn = this._registeredEventHandlers[eventName];
      if (fn) {
        await fn(...args);
      } else {
        this._logError('#ServerEvent No handler for event:', eventName);
      }
    });
  };

  _applyEventHandlers = () => {
    this._logDebug('#applyEventHandlers');
    for (let eventName in this._registeredEventHandlers) {
      const handler = this._registeredEventHandlers[eventName];
      this._connection.off(eventName, handler);
      this._connection.on(eventName, handler);
    }
  };

  _removeAllEventHandlersFromConnection = (connection) => {
    this._logDebug('#removeAllEventHandlersFromConnection');
    for (let eventName in this._registeredEventHandlers) {
      connection.off(eventName);
    }
  };

  subscribeToClientEvent = (eventName, callback) => {
    if (!eventName) {
      this._logError('eventName is empty #subscribeToClientEvent');
      throw new Error('eventName is empty');
    }

    if (typeof callback !== 'function') {
      this._logError('callback is not a function #subscribeToClientEvent');
      throw new Error('callback is not a function');
    }

    // console.debug(
    //   `Subscribing to client event: ${eventName} #subscribe #SignalRConnection`
    // );

    this._eventEmitter.off(eventName, callback);
    this._eventEmitter.on(eventName, callback);
  };

  onceClientEvent = (eventName, callback) => {
    if (!eventName) {
      this._logError('eventName is empty #onceClientEvent');
      throw new Error('eventName is empty');
    }

    if (typeof callback !== 'function') {
      this._logError('callback is not a function #onceClientEvent');
      throw new Error('callback is not a function');
    }

    this._eventEmitter.once(eventName, callback);
  };

  checkConnected = async () => {
    if (this._connection.state !== HubConnectionState.Connected) {
      return false;
    }
    try {
      await this._connection.invoke('Ping');
      const result = this._connection.state === HubConnectionState.Connected;
      if (result) {
        this._logDebug('Connection is established. Its ok. #verifyConnected');
      }
      return result;
    } catch {
      const state = this._connection.state;
      if (state === HubConnectionState.Connected) {
        this._logDebug(
          'Connection is established, but ping failed. Its ok. #verifyConnected'
        );

        return true;
      }

      return false;
    }
  };

  _prevRestartCancellationToken = null;

  ensureConnected = async (firstAwaitMs = 10_000) => {
    const oldCancellationToken = this._prevRestartCancellationToken;
    const newCancellationToken = { isCancelled: false };
    this._prevRestartCancellationToken = newCancellationToken;

    const cancelPrevToken = () => {
      if (oldCancellationToken && !oldCancellationToken.isCancelled) {
        oldCancellationToken.isCancelled = true;
        this._logDebug('oldCancellationToken is cancelled #ensureConnected');
      }
    };

    this._logDebug(
      '#ensureConnected',
      '\n',
      `firstAwaitMs: ${firstAwaitMs}`,
      'oldCancellationTokenIsCancelled:',
      oldCancellationToken?.isCancelled
    );

    let connected = await this.checkConnected();
    if (connected) {
      this._emit(emitEvents.connectionEstablished);
      return;
    }

    await sleep(300);

    if (this._connection.state === HubConnectionState.Disconnected) {
      cancelPrevToken();
      await this._restart('ensureConnected_1', newCancellationToken);
      // do not return here, because we need to check connection state
    }

    connected = await waitUntil(
      '#SignalRConnection #ensureConnected ' + this.host,
      firstAwaitMs,
      200,
      () =>
        newCancellationToken.isCancelled ||
        this._connection.state === HubConnectionState.Connected
    );

    if (newCancellationToken.isCancelled) {
      this._logDebug('cancellationToken is cancelled - 1 #ensureConnected');
      return;
    }

    if (!connected) {
      this._logWarn(
        '#ensureConnected Connection is not established even after waiting for recovery...',
        '\n',
        'Trying to restart connection...'
      );

      this._emit(emitEvents.connectionNotEstablished);

      const timeId = setTimeout(() => {
        this._logError('Connection reset is not finished in 10sec ...');
      }, 10_000);

      cancelPrevToken();
      await this._restart('ensureConnected_2', newCancellationToken);
      clearTimeout(timeId);
      // need to wait for connection to be established after restart,
      // since it may be a fake return, in case of a parallel process (see the restart method implementation)
      connected = await waitUntil(
        '#SignalRConnection #ensureConnected ' + this.host,
        OneDayMs,
        500,
        () =>
          newCancellationToken.isCancelled ||
          this._connection.state === HubConnectionState.Connected
      );
    }

    if (newCancellationToken.isCancelled) {
      this._logDebug('cancellationToken is cancelled - 2 #ensureConnected');
      return;
    }

    connected = await this.checkConnected();

    if (newCancellationToken.isCancelled) {
      this._logDebug('cancellationToken is cancelled - 3 #ensureConnected');
      return;
    }

    if (connected) {
      this._emit(emitEvents.connectionEstablished);
      return;
    }

    this._logError(
      '#ensureConnected Connection is not established even after reconnection (while trying to ensureConnected)'
    );

    this._emit(emitEvents.cannotEstablishConnection);
  };

  // use invokeWithRetry or invokeImmediately. Keeping it for backward compatibility
  invoke = async (methodName, ...args) => {
    this._logWarn(
      'Deprecated method. Use invokeWithRetry or invokeImmediately instead'
    );

    return await this.invokeImmediately(methodName, ...args);
  };

  invokeWithRetry = async (methodName, ...args) => {
    this._assertAuthorized();

    return await invokeConfigurable(
      this.host,
      () => this._connection,
      (...args) => this._emit(...args),
      methodName,
      false,
      true,
      args
    );
  };

  // works without waiting for the connection recovery
  invokeImmediately = async (methodName, ...args) => {
    this._assertAuthorized();

    return await invokeConfigurable(
      this.host,
      () => this._connection,
      (...args) => this._emit(...args),
      methodName,
      true,
      true,
      args
    );
  };

  invokeImmediatelySilently = async (methodName, ...args) => {
    this._assertAuthorized();

    return await invokeConfigurable(
      this.host,
      () => this._connection,
      (...args) => this._emit(...args),
      methodName,
      true,
      false,
      args
    );
  };

  _assertAuthorized = () => {
    if (this.unauthorized) {
      throw new Error('Unauthorized');
    }
  };
}

export function getErrorDetails(err) {
  try {
    // parse SignalR hardcoded string
    const msgJson = (
      err.message.match(/HubException: (.*)/) || [err.message]
    ).pop();
    return JSON.parse(msgJson);
  } catch (e) {
    return { id: null, message: err.message };
  }
}

export const connMarkets = new SignalRConnection(config.wsUrl + 'markets');
export const connApp = new SignalRConnection(config.wsUrl + 'app', true);
