export const isAndroidDxsApp = () => {
  const userAgent = window.navigator.userAgent;

  return userAgent === 'DXS android app';
};

export const isIOSDxsApp = () => {
  const userAgent = window.navigator.userAgent;

  return userAgent === 'DXS ios app';
};

export const isIOS = () => {
  const userAgent = window.navigator.userAgent.toLowerCase();
  return /iphone|ipad|ipod/.test(userAgent);
};

export const isAndroid = () => {
  const userAgent = window.navigator.userAgent.toLowerCase();
  return userAgent.indexOf('android') > -1;
};

export const hasStandaloneSupport = () => 'standalone' in window.navigator;
export const installedStandalone = () =>
  'standalone' in window.navigator && window.navigator.standalone;

export const isRunningStandaloneAndroid = () => {
  return window.matchMedia('(display-mode: standalone)').matches;
};

export const isMobileUserAgent = () => {
  return /Mobi|Android|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/.test(
    navigator.userAgent
  );
};
