import { DOCUMENT_STATES } from '@/helpers/enums';
import { watch } from 'vue';

const state = {
  isVisible: true,
  lastSleepDate: null,
  isProcessing: false,
};

const getters = {
  isVisible: (state) => state.isVisible,
  isProcessing: (state) => state.isProcessing,
};

const mutations = {
  setVisibility(state, value) {
    console.debug('#uiVisibility #setVisibility', 'value:', value);
    state.isVisible = value;
  },

  setLastSleepDate(state) {
    state.lastSleepDate = new Date();
  },

  setIsProcessing(state, value) {
    state.isProcessing = value;
  },
};

const actions = {
  async init({ commit, dispatch, getters }) {
    const checkVisibility = () => {
      const currentState = document.visibilityState;
      const isVisible = currentState === DOCUMENT_STATES.visible;
      commit('setVisibility', isVisible);
    };

    document.addEventListener('visibilitychange', () => {
      console.log('#visibilitychange #uiVisibility');
      checkVisibility();
    });

    window.addEventListener('focus', () => {
      console.log('#focus #uiVisibility');
      dispatch('_onVisible');
    });

    watch(
      () => getters.isVisible,
      async (value) => {
        commit('setIsProcessing', true);
        try {
          if (value) {
            console.log('UNSLEEP #uiVisibility');
            await dispatch('_onVisible');
          } else {
            console.log('SLEEP #uiVisibility');
            commit('setLastSleepDate');
            await dispatch('_onInvisible');
          }
        } finally {
          commit('setIsProcessing', false);
        }
      }
    );
  },

  async _onVisible({ state, dispatch, rootGetters }) {
    const longTimeoutMs = 20_000;

    const longTimePassed =
      !state.lastSleepDate || new Date() - state.lastSleepDate > longTimeoutMs;

    console.debug(
      '_onVisible #uiVisibility',
      'lastSleepDate:' + state.lastSleepDate,
      'longTimePassed:' + longTimePassed
    );

    // [Vadim] disabled since now it looks like we don't need to show loader after sleep,
    // we need it only after disconnect?

    // if (longTimePassed) {
    //   commit('markets/SET_LOADING_AFTER_SLEEP', true, { root: true });
    //   commit('positions/UPDATE_LOADING_SLEEP_STATUS', true, { root: true });
    //   commit('session/SET_LOADING_AFTER_SLEEP', true, { root: true });
    // }

    await dispatch('network/ensureConnectionsAreActive', null, {
      root: true,
    });

    const isLoggedId = rootGetters['auth/isLoggedIn'];
    if (longTimePassed && isLoggedId && rootGetters['positions/sleepLoading']) {
      // this condition is to avoid double loading in case if signalR was reconnected
      // (refreshUserPositions is also called on reconnection)
      await dispatch('positions/refreshUserPositions', null, {
        root: true,
      });
    }
  },

  async _onInvisible() {
    console.log('onInvisible #uiVisibility');
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
