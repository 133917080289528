import { connMarkets } from '@/helpers/signalR';
import { createEntityFromMarket } from '@/modules/markets/helpers';
import { getSleepInfo } from '@/helpers/sleep';
import throttle from 'lodash/throttle';
import debounce from 'lodash/debounce';

const STARVING_TIMEOUT = 15_000;
const DELAY_BEFORE_FLUSH_MS = 1200;

let marketCache = {};

const flushThrottled = throttle((store) => {
  // console.log('flushThrottled');
  const cachedMarkets = Object.values(marketCache);
  store.commit('markets/UPDATE_MARKETS', cachedMarkets);
  marketCache = {};
}, DELAY_BEFORE_FLUSH_MS);

const controlTickerStarvingDebounced = debounce((store) => {
  console.log('controlStarvingDebounced');
  store.commit('markets/SET_TICKER_STARVING');
}, STARVING_TIMEOUT);

const onTickerReceive = (store, marketUpdates) => {
  // console.log('onTickerReceive', marketUpdates);
  const fullMarket = {
    ...store.getters['markets/marketsById'][marketUpdates.id],
    ...marketUpdates,
  };

  if (fullMarket.name) {
    const market = createEntityFromMarket(fullMarket);
    marketCache[market.id] = market;

    if (document[getSleepInfo().hidden]) {
      return;
    }

    store.commit('markets/TICKER_RECEIVED');
    flushThrottled(store);
  } else {
    // market has no name in the beginning, probably when markets not loaded yet
  }

  controlTickerStarvingDebounced(store);
};

function onDeleteMarket(store, id) {
  store.commit('markets/DELETE_MARKET', id);
}

export default (store) => {
  const onMarketDeleted = (data) => onDeleteMarket(store, data);
  const onTicker = (data) => {
    const [id, buy, sell, price24h, timeline] = data;
    onTickerReceive(store, { id, buy, sell, price24h, timeline });
  };

  connMarkets.subscribeToServerEvent('OnMarketDisabled', onMarketDeleted);
  connMarkets.subscribeToServerEvent('OnTickerSlim', onTicker);
};
