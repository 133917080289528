let startDate;

function getElapsedSecondsAfterLoadStart() {
  if (!startDate) {
    return '-';
  }
  return (new Date() - startDate) / 1000;
}

export function initElapsedLoadTime() {
  startDate = new Date();
}

export function showElapsedLoadTime(message) {
  console.log(
    message,
    '\n',
    'Elapsed Load time:',
    `${getElapsedSecondsAfterLoadStart()} seconds`,
    '\n',
    '#loadTimeTracker'
  );
}
