import { connApp } from '@/helpers/signalR';

// todo: [Vadim] remove if won't be needed, commented out for now
// export const getBountyBalance = async () => {
//   return await connApp.invokeWithRetry('GetBountyBalance');
// };

export const getBountyTasks = async () => {
  return await connApp.invokeWithRetry('GetBountyStatus');
};

export const getPositionStatus = async () => {
  return await connApp.invokeWithRetry('IsOpenedBountyPosition');
};

export const proposeBountyPosition = (msg, amount) => {
  return connApp.invokeImmediately('ProposeBountyPosition', {
    TransactionMessage: msg,
    TransactionAmount: amount,
  });
};
