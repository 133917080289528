<template>
  <div v-if="refundData.length" class="liquidity-provisions">
    <div class="flex-row">
      <div class="column">{{ $t('popups.liquidityFundraiser.txfield') }}</div>
      <div class="column">
        {{ $t('popups.liquidityFundraiser.penaltyfield') }}
      </div>
    </div>
    <div
      v-for="(tx, ndx) in refundData"
      :key="ndx"
      :class="{ refunded: !txStatuses.indexOf(tx.amountAvailableStatus) }"
      class="liquidity-provisions__item"
    >
      <div class="liquidity-provisions__column">
        <a
          :href="`https://whatsonchain.com/tx/${tx.transactionId}`"
          target="_blank"
          rel="noopener noreferrer"
        >
          {{ new Date(tx.transactionDate).toLocaleDateString() }}
        </a>
      </div>
      <div class="liquidity-provisions__column">
        <span class="liquidity-provisions__column">{{
          getRefundTitle(tx)
        }}</span>
      </div>
      <div class="liquidity-provisions__column">
        <Button
          v-if="txStatuses.indexOf(tx.amountAvailableStatus) !== 0"
          :text="
            txStatuses.indexOf(tx.amountAvailableStatus) < 2
              ? 'LOCKUP'
              : 'REFUND'
          "
          :disabled="txStatuses.indexOf(tx.amountAvailableStatus) < 2"
          type="secondary"
          size="medium"
          class="button"
          @click="refund(tx)"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { onMounted, ref } from 'vue';
import { getErrorDetails, connApp } from '@/helpers/signalR';
import notify from '@/plugins/notify';
import Button from '@/components/ui/Button';

export default {
  components: { Button },
  setup(_, { emit }) {
    const txStatuses = ref([
      'Withdrawn',
      'NotWithdrawable',
      'PartiallyWithdrawable',
      'FullyWithdrawable',
    ]);
    const refundData = ref([]);

    const refund = (tx) => {
      emit('refund-tx', tx);
    };

    const getRefundTitle = (tx) => {
      let title = '';

      switch (txStatuses.value.indexOf(tx.amountAvailableStatus)) {
        case 0:
          title = 'Refunded';
          break;
        case 1:
          title = '30 days';
          break;
        case 2:
          title = '30% + Return';
          break;
        case 3:
          title = 'Return';
          break;
      }
      return title;
    };

    onMounted(async () => {
      try {
        const result = await connApp.invokeWithRetry(
          'GetUserLiquidityRefundV2'
        );
        refundData.value = result.refunds;
      } catch (err) {
        const error = getErrorDetails(err);
        notify({
          text: error.message,
          type: 'info',
        });
      }
    });

    return {
      txStatuses,
      refundData,
      getRefundTitle,
      refund,
    };
  },
};
</script>
<style lang="scss" scoped>
.liquidity-provisions {
  display: flex;
  flex-direction: column;

  .flex-row {
    display: flex;

    .column {
      margin-bottom: 15px;
      width: 33%;
    }
  }

  &__column {
    width: 33%;
    margin-bottom: 10px;
  }

  &__status {
    margin: 0 15px;
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &.refunded {
      a,
      span {
        color: #bbb;
      }
    }

    .button {
      width: 100px;
      min-height: 35px;
      height: 35px;
    }
  }
}
</style>
