import { connApp, getErrorDetails } from '@/helpers/signalR';

const types = {
  SET_FEATURES: 'SET_FEATURES',
  SET_FEATURES_COUNT: 'SET_FEATURES_COUNT',
  SET_FEATURES_AMOUNT_COLLECTED: 'SET_FEATURES_AMOUNT_COLLECTED',
  SET_FEATURES_AMOUNT_REQUIRED: 'SET_FEATURES_AMOUNT_REQUIRED',
};

const state = {
  features: [],
  featuresCount: 0,
  featuresAmountCollected: 0,
  featuresAmountRequired: 0,
};

const getters = {
  getFeatures: (state) => state.features,
  getFeaturesCount: (state) => state.featuresCount,
  getFeaturesAmountCollected: (state) => state.getFeaturesAmountCollected,
  getFeaturesAmountRequired: (state) => state.getFeaturesAmountRequired,
};

const mutations = {
  [types.SET_FEATURES](state, value) {
    state.features = value;
  },
  [types.SET_FEATURES_COUNT](state, value) {
    state.featuresCount = value;
  },
  [types.SET_FEATURES_AMOUNT_COLLECTED](state, value) {
    state.featuresAmountCollected = value;
  },
  [types.SET_FEATURES_AMOUNT_REQUIRED](state, value) {
    state.featuresAmountRequired = value;
  },
};

const actions = {
  async fetchFeatures({ commit }) {
    let error;
    const result = await connApp.invokeWithRetry('GetFeatures').catch((err) => {
      error = getErrorDetails(err);
    });

    if (!error && result && result.features.length) {
      commit(types.SET_FEATURES, result.features);
      commit(types.SET_FEATURES_COUNT, result.count);
      commit(types.SET_FEATURES_AMOUNT_COLLECTED, result.totalAmountCollected);
      commit(types.SET_FEATURES_AMOUNT_REQUIRED, result.totalAmountRequired);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
