import assign from 'lodash/assign';
import findIndex from 'lodash/findIndex';
import groupBy from 'lodash/groupBy';
import { sortMarkets } from '../helpers';
import { DEFAULT_MARKET_BSV_USD } from '@/config/constants';

export const types = {
  SET_MARKETS: 'SET_MARKETS',
  DELETE_MARKET: 'DELETE_MARKET',
  UPDATE_MARKET: 'UPDATE_MARKET',
  UPDATE_MARKETS: 'UPDATE_MARKETS',
  UPDATE_ACTIVE_MARKET: 'UPDATE_ACTIVE_MARKET',
  SET_DONT_SHOW_AGAIN_MARKET: 'SET_DONT_SHOW_AGAIN_MARKET',
  SET_LOADING_AFTER_SLEEP: 'SET_LOADING_AFTER_SLEEP',
  UPDATE_LOADING_STATUS: 'UPDATE_LOADING_STATUS',
  TICKER_RECEIVED: 'TICKER_RECEIVED',
  SET_TICKER_STARVING: 'SET_TICKER_STARVING',
};

const state = {
  loadingAfterSleep: false,
  markets: [],
  marketsLoading: true,
  marketsById: {},
  marketsByName: {},
  marketsByGroup: {},
  activeMarketId: 0,
  dontShowAgain: false,
  tickerStarving: false,
  defaultSort: {
    field: ['group_order', 'ticker'],
    type: ['asc', 'asc'],
  },
};

const findDefaultMarketId = (markets) => {
  const defaultMarketName = DEFAULT_MARKET_BSV_USD;
  const market = markets.find((it) => it.name === defaultMarketName);

  if (!market) {
    throw new Error(
      `Unable to find default=${defaultMarketName} market to set as active.`
    );
  }

  return market.id;
};

const getters = {
  loadingAfterSleep: (state) => state.loadingAfterSleep,

  marketsLoading: (state) => state.marketsLoading,

  dontShowAgain: (state) => state.dontShowAgain,

  markets: (state) => state.markets,

  marketsByName: (state) => state.marketsByName,

  activeMarketId: (state) => state.activeMarketId,

  activeMarket: (state) => state.marketsById[state.activeMarketId],

  marketsById: (state) => state.marketsById,

  getMarketsByGroup: (state) => (group) => state.marketsByGroup[group],

  getGroupedMarkets: (state) => state.marketsByGroup,

  tickerStarving: (state) => state.tickerStarving,
};

const mutations = {
  [types.SET_LOADING_AFTER_SLEEP](state, flag) {
    state.loadingAfterSleep = flag;
  },

  [types.SET_DONT_SHOW_AGAIN_MARKET](state) {
    state.dontShowAgain = true;
  },
  [types.DELETE_MARKET](state, marketId) {
    const market = state.marketsById[marketId];

    state.marketsById[marketId] = null;
    state.marketsByName[market.name] = null;

    const indexToDelete = findIndex(state.markets, ['id', marketId]);

    state.markets.splice(indexToDelete, 1);
  },
  [types.SET_MARKETS](state, markets) {
    state.markets = sortMarkets(markets);

    window.SET_MARKETS = state.markets;

    const byIds = state.markets.reduce(
      (table, market) => assign(table, { [market.id]: market }),
      {}
    );
    const byName = state.markets.reduce(
      (table, market) => assign(table, { [market.name]: market }),
      {}
    );
    const byGroup = groupBy(state.markets, 'group');

    state.marketsById = byIds;
    state.marketsByName = byName;
    state.marketsByGroup = byGroup;
    if (!state.activeMarketId) {
      state.activeMarketId = findDefaultMarketId(markets);
    }

    // [Vadim] keep in the end
    state.marketsLoading = false;
  },
  [types.UPDATE_MARKETS](state, markets) {
    markets.forEach((rawMarket) => {
      const reactiveMarket = state.marketsById[rawMarket.id];

      Object.assign(reactiveMarket, rawMarket);
    });
  },
  [types.UPDATE_ACTIVE_MARKET](state, market) {
    state.activeMarketId = market && market.id;
  },
  [types.UPDATE_LOADING_STATUS](state, value) {
    state.marketsLoading = value;
  },

  [types.TICKER_RECEIVED](state) {
    state.tickerStarving = false;
    state.marketsLoading = false;
    state.loadingAfterSleep = false;
  },

  [types.SET_TICKER_STARVING](state) {
    state.tickerStarving = true;
  },
};

const actions = {
  setLoadingAfterSleep({ commit }, flag) {
    commit(types.SET_LOADING_AFTER_SLEEP, flag);
  },
  // @ts-ignore
  setDontShowAgainMarket({ commit }) {
    commit(types.SET_DONT_SHOW_AGAIN_MARKET);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
